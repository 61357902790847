import React, { CSSProperties } from "react";
import { ModalView } from './ModalView';
import { StartGameOptions, ReloadGameOptions } from '@wargamer/types';

export interface StartGameModalProps {
  teamId: string;
  onStart: (gameOptions: StartGameOptions) => void;
  onReloadGameData: (reloadOptions: ReloadGameOptions) => void;
}

const inputStyle: CSSProperties = {
  backgroundColor: '#D9D9D9',
  border: 'none',
  height: 29,
  padding: '4px 8px',
  boxSizing: 'border-box'
}

export function StartGameModal(props: StartGameModalProps) {

  const [scenarioTime, setScenarioTime] = React.useState('20');
  const [inGameTime, setInGameTime] = React.useState('84');

  const timeDilation = React.useMemo(() => {

    const st = parseInt(scenarioTime) || 1;
    const gt = parseInt(inGameTime) || (st / 24);
    const inGameTimeHr = gt * 24;

    const td = inGameTimeHr / st;

    return parseFloat(td.toFixed(2));
  }, [scenarioTime, inGameTime]);

  const customConfigSheet = React.useMemo(() => {
    const url = new URL(document.location.toString());
    const sheet = (url.searchParams.get('sheet') || '').trim();

    if(sheet.startsWith('https://') !== true) {
      return '';
    }
  
    return sheet;
  }, []);

  if (props.teamId !== 'white') {
    return <ModalView title="Scenario Pending...">
      <span>
        This simulation has not started yet. The administrator (white) must first start the simulation before the simulation can begin.
      </span>
    </ModalView>
  }

  const onStart = () => {
    props.onStart({
      scenarioTime: parseInt(scenarioTime),
      inGameTime: parseInt(inGameTime)
    });
  }

  return <ModalView title="Scenario Pending...">
    <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
      <div>
        This game has not started yet. Please enter the time you have set aside for your simulation (Scenario Time) and the length that your scenario exercise represents and then click “start game” to begin.
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', gap: 12, justifyContent: 'space-between' }}>

        <div style={{ display: 'flex', flexDirection: 'column', gap: 8, width: 190 }}>
          <div>Simulation Duration (hours)</div>
          <input
            style={inputStyle}
            type="number"
            value={scenarioTime}
            onChange={e => setScenarioTime(e.target.value)} />
            <div style={{fontSize: 10}}>This is the length of your simulation in actual hours.</div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: 8, width: 190}}>
          <div>Scenario Length (days)</div>
          <input 
            style={inputStyle}
            type="number"
            value={inGameTime}
            onChange={e => setInGameTime(e.target.value)} />
          <div style={{fontSize: 10}}>This is how much time would be represented by your simulation if it actually played out in real life. </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: 8, width: 190 }}>
          <div>Time Multiple {timeDilation}x<br/>sped up</div>
          <div style={{fontSize: 10}}>A day will pass in {Math.round((86000 / timeDilation) / 60)} minutes.</div>
        </div>

      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        {customConfigSheet ? <>
            <em>You have a custom sheet specified. Starting this game will attempt to load game data from the sheet.</em>
        </> : <></>}
        <button style={{
          fontWeight: 'bold',
          border: 'none',
          outline: 'none',
          cursor: 'pointer',
          width: 140,
          height: 40,
          borderRadius: .5,
          backgroundColor: '#D9D9D9'
        }} onClick={() => props.onReloadGameData({customConfig: customConfigSheet})}>Reload Game Data</button>
      </div>

      <div style={{display: 'flex', alignItems: 'center'}}>
        <button style={{
          fontWeight: 'bold',
          border: 'none',
          outline: 'none',
          cursor: 'pointer',
          width: 117,
          height: 40,
          borderRadius: .5,
          backgroundColor: '#D9D9D9'
        }} onClick={onStart}>Start Game</button>
      </div>

    </div>
  </ModalView>;
}