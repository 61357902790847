import React from "react";
import './Breadcrumbs.scss';

export interface BreadcrumbProps {
  items: Item[];
  onClick: (id: string) => void;
}

export interface Item {
  id: string;
  label: string;
}

export function Breadcrumbs(props: BreadcrumbProps) {
  const items = React.useMemo(() => {
    return props.items.map((item, index, arr) => {
      const fragment = <div
        key={item.id}
        className="breadcrumb-item"
        onClick={() => props.onClick(item.id)}
      >
        {item.label}
      </div>;

      if(index === 0) {
        return fragment;
      } else {
        return <React.Fragment key={item.id}>
          <div>{">"}</div>
          {fragment}
        </React.Fragment>;
      }
    });
  }, [props.items]);

  return <div className="breadcrumbs">
    {items}
  </div>
}