import { GameStateProvider, useGameSession, useGameState, createNewContext } from './hooks/GameStateProvider';
import { getPlayerToken, useRts, useEvents } from './api';
import { useAsync } from './hooks/useAsync';
import React from 'react';
import { PlayerView } from './PlayerView';
import { DataEvent } from '@wargamer/types';
import { useParams } from 'react-router-dom';

export interface GameAppProps {
  gameId: string;
  playerId?: string;
}

const ctx = createNewContext();

export function GameApp() {
  const {gameId, playerId} = useParams();
  const [context] = React.useState(ctx);
  return <GameStateProvider context={context}>
    <GameAppInternal gameId={gameId || ''} playerId={playerId || ''}/>
  </GameStateProvider>
}

function GameAppInternal(props: GameAppProps) {
  const state = useGameState();
  (window as any)['state' as any] = state;
  const session = useGameSession();
  const login = useAsync(getPlayerToken, {
    onSuccess: result => session.set({
      gameId: result.gameId,
      playerToken: result.token,
      playerId: result.playerId,
      playerTeam: result.teamId,
    })
  });

  React.useEffect(() => {
    login.call(props.gameId, props.playerId);
  }, [props.gameId]);

  useRts(props.gameId, session?.playerToken);
  useEvents((event: DataEvent) => {
    state.log.apply(event);
  })

  return <PlayerView/>;
}
