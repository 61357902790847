import axios from 'axios';
import { API_HOST } from '../config';
import { Action } from '@wargamer/types';

export async function runAction(
  gameId: string,
  action: Action,
  token: string
) {
  return axios.post(`${API_HOST}/game-play/${gameId}/actions/${action.action}`, action, {
    headers: {
      token
    }
  });
}
