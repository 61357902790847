import React from "react";
import './ActionButton.scss';

export declare type Actions = 'attack' | 'deselect' | 'find-attack' | 'move' | 'shutdown' | 'find-actor' | 'locate' | 'stop';

export interface ActionButtonProps {
  action: Actions;
  onClick: (action: Actions) => void;
}

export function ActionButton(props: ActionButtonProps) {
  const className = React.useMemo(() => {
    return `action-button ${props.action}`;
  }, [props.action]);

  const html = React.useMemo(() => {
    return {__html: icons[props.action]}
  }, [props.action]);

  return <div
    onClick={() => props.onClick(props.action)}
    className={className}
    dangerouslySetInnerHTML={html}></div>;
}

const icons: {[action: string]: string} = {
  attack: `<svg width="682" height="682" viewBox="0 0 682 682" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M260.865 409.573L178.056 502.477C181.661 503.59 185.163 505.136 188.504 507.061C187.297 499.329 189.857 490.817 195.414 485.26C201.574 479.099 208.817 477.824 213.828 477.949C214.025 469.384 216.773 464.025 221.196 459.488L222.226 458.437C227.913 452.636 231.664 448.915 238.377 447.203C239.672 442.703 241.977 438.691 245.177 435.491L265.98 414.689L260.865 409.573Z" fill="white"/>
  <path d="M220.971 495.476C216.928 491.355 209.706 491.407 205.631 495.476C201.682 499.425 201.62 506.85 205.605 510.789C205.61 510.794 205.621 510.81 205.631 510.815L242.35 547.534C246.492 551.712 253.537 551.696 257.695 547.534C261.884 543.345 261.894 536.357 257.695 532.194L220.971 495.476Z" fill="white"/>
  <path d="M136.866 525.42C122.536 539.744 122.574 563.086 136.934 577.448C151.294 591.809 174.646 591.845 188.962 577.515C203.079 563.389 203.005 539.454 188.962 525.41C174.601 511.055 151.232 511.054 136.866 525.42Z" fill="white"/>
  <path d="M239.619 465.758C239.198 464.79 238.823 463.812 238.506 462.823L231.435 469.686L231.429 469.692C227.308 473.792 227.257 480.967 231.403 485.01L268.117 521.802C272.269 525.996 279.34 525.949 283.498 521.755L289.294 515.959C293.514 511.729 293.514 504.845 289.294 500.614L269.84 481.159C257.612 483.766 244.974 477.626 239.619 465.758Z" fill="white"/>
  <path d="M283.177 474.07L299.505 490.398C303.615 494.519 310.718 494.519 314.802 490.44L320.598 484.643C322.654 482.588 323.788 479.861 323.788 476.979C323.788 474.096 322.648 471.365 320.598 469.314L304.276 452.986L283.177 474.07Z" fill="white"/>
  <path d="M420.789 418.005C417.963 420.83 413.39 420.83 410.564 418.005L296.4 303.84C293.575 301.015 293.575 296.441 296.4 293.616L314.57 275.446L290.839 251.714L255.186 287.377L284.048 331.323C285.932 332.551 287.711 333.94 289.511 335.298C293.351 338.181 296.978 341.157 300.381 344.565L337.408 381.592C342.543 386.743 345.171 393.903 345.327 401.103L358.247 414.023L427.029 459.219L462.682 423.556L438.956 399.83L420.789 418.005Z" fill="white"/>
  <path d="M136.533 415.967C131.382 426.233 133.38 438.581 141.513 446.713L176.874 482.074L242.18 408.802L208.994 408.828H208.988C207.063 408.828 205.232 408.063 203.874 406.705L183.415 386.241C180.59 383.415 180.59 378.842 183.415 376.016C186.24 373.191 190.814 373.191 193.639 376.016L211.985 394.368L263.066 394.326C264.991 394.326 266.823 395.091 268.181 396.449L281.314 409.582C284.139 412.408 284.139 416.981 281.314 419.807L255.397 445.724C251.65 449.46 250.682 455.137 252.821 459.877C255.953 466.828 263.576 468.592 269.07 466.313C269.091 466.308 269.107 466.308 269.123 466.297C269.143 466.292 269.159 466.277 269.175 466.271C270.694 465.663 272.021 464.783 273.233 463.566H273.238L327.17 409.629C332.05 404.629 332.019 396.663 327.17 391.829L290.143 354.803C285.595 350.276 280.423 346.176 274.991 342.731C274.986 342.726 274.976 342.71 274.97 342.71C274.965 342.71 274.965 342.71 274.955 342.705C261.999 334.479 247.034 329.801 231.669 329.192L218.88 328.682C217.495 328.682 214.993 327.735 213.759 326.569L141.257 254.047L61.3711 333.933L135.163 407.616C136.927 409.874 137.936 413.167 136.531 415.967H136.533Z" fill="white"/>
  <path d="M335.646 421.615L314.51 442.755L330.833 459.083C335.074 463.313 341.963 463.298 346.178 459.083L351.969 453.282C354.03 451.226 355.169 448.5 355.169 445.617C355.169 442.735 354.03 440.003 351.98 437.953L335.646 421.615Z" fill="white"/>
  <path d="M330.304 317.303L397.077 384.082L601.424 200.115L620.624 93.7604L514.264 112.955L330.304 317.303Z" fill="white"/>
  </svg>
  `,

  deselect: `<svg width="682" height="682" viewBox="0 0 682 682" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M559.24 498.398L401.842 341L559.24 183.602L498.398 122.76L341 280.158L183.602 122.76L122.76 183.602L280.158 341L122.76 498.398L183.602 559.24L341 401.842L498.398 559.24L559.24 498.398Z" fill="white"/>
  </svg>
  `,
  'find-attack': `<svg width="682" height="682" viewBox="0 0 682 682" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M116.255 301.098C116.255 199.013 199.015 116.254 301.101 116.254C403.186 116.254 485.946 199.013 485.946 301.098C485.946 403.183 403.186 485.941 301.101 485.941C199.015 485.941 116.255 403.183 116.255 301.098ZM301.101 63.4419C169.849 63.4419 63.4419 169.847 63.4419 301.098C63.4419 432.348 169.849 538.754 301.101 538.754C357.214 538.754 408.783 519.304 449.444 486.786L605.202 642.543C615.511 652.858 632.234 652.858 642.543 642.543C652.858 632.234 652.858 615.511 642.543 605.203L486.784 449.446C519.302 408.786 538.753 357.218 538.753 301.104C538.753 169.854 432.352 63.4419 301.101 63.4419Z" fill="white"/>
  <g clip-path="url(#clip0_392_182)">
  <path d="M469.816 295.351H427.291C424.565 230.472 372.225 178.132 307.346 175.406V132.882C307.346 129.61 304.62 126.884 301.349 126.884C298.077 126.884 295.351 129.61 295.351 132.882V175.406C230.472 178.132 178.132 230.472 175.406 295.351H132.882C129.61 295.351 126.884 298.077 126.884 301.349C126.884 304.62 129.61 307.346 132.882 307.346H175.406C178.678 372.225 230.472 424.02 295.351 427.291V469.816C295.351 473.087 298.077 475.813 301.349 475.813C304.62 475.813 307.346 473.087 307.346 469.816V427.291C372.225 424.019 424.02 372.225 427.291 307.346H469.816C473.087 307.346 475.813 304.62 475.813 301.349C475.813 298.077 473.087 295.351 469.816 295.351ZM415.295 295.351H392.941C390.215 249.554 353.143 213.025 307.344 209.754V187.4C365.683 190.674 412.023 237.017 415.295 295.35L415.295 295.351ZM295.351 295.351H221.748C225.02 256.098 256.097 225.02 295.351 221.748V295.351ZM295.351 307.347V380.949C256.097 377.677 225.019 346.6 221.748 307.347H295.351ZM307.346 307.347H380.949C377.677 346.6 346.6 377.678 307.346 380.949V307.347ZM307.346 295.351V221.748C346.6 225.02 377.678 256.098 380.949 295.351H307.346ZM295.351 187.401V209.755C249.554 212.481 213.025 249.553 209.754 295.352H187.4C190.674 237.013 237.017 190.672 295.35 187.401L295.351 187.401ZM187.4 307.345H209.754C212.48 353.142 249.553 389.671 295.351 392.942V415.296C237.013 412.022 190.672 365.679 187.401 307.346L187.4 307.345ZM307.345 415.296V392.942C353.141 390.216 389.671 353.143 392.942 307.345H415.296C412.022 365.683 365.679 412.024 307.346 415.295L307.345 415.296Z" fill="white"/>
  </g>
  <defs>
  <clipPath id="clip0_392_182">
  <rect width="348.93" height="348.93" fill="white" transform="translate(126.884 126.884)"/>
  </clipPath>
  </defs>
  </svg>
  `,
  move: `<svg width="682" height="682" viewBox="0 0 682 682" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M297.859 282.428H369.933V185.755H416.575L333.907 42.625L251.239 185.755H297.874V282.428H297.859ZM369.933 413.765H297.859V510.437H251.224L333.892 653.583L416.56 510.437H369.917V413.765H369.933ZM268.223 312.054H171.569V265.445L28.4165 348.101L171.569 430.757V384.125H268.223V312.054ZM639.375 348.101L496.245 265.445V312.078H399.591V384.148H496.245V430.781L639.375 348.101Z" fill="white"/>
  </svg>
  `,
  shutdown: `<svg width="682" height="682" viewBox="0 0 682 682" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M418.684 137.426L400.816 188.917C455.585 207.925 498.06 251.828 515.24 307.197C532.426 362.564 522.27 422.803 487.882 469.478C453.499 516.151 398.979 543.708 340.998 543.708C283.018 543.708 228.504 516.151 194.115 469.478C159.728 422.805 149.571 362.567 166.757 307.197C183.937 251.83 226.412 207.925 281.181 188.917L263.313 137.358C210.37 155.73 165.643 192.263 137.077 240.476C108.511 288.688 97.9428 345.461 107.262 400.719C116.575 455.982 145.167 506.157 187.959 542.341C230.756 578.524 284.984 598.374 341.022 598.374C397.061 598.374 451.289 578.524 494.086 542.341C536.878 506.157 565.47 455.982 574.783 400.719C584.102 345.456 573.54 288.683 544.968 240.476C516.403 192.263 471.676 155.73 418.732 137.358L418.684 137.426Z" fill="white"/>
  <path d="M313.72 83.5437H368.281V367.12H313.72V83.5437Z" fill="white"/>
  </svg>
  `,
  'find-actor': `<svg width="682" height="682" viewBox="0 0 682 682" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M341 70.2433C244.839 70.2433 167.09 147.99 167.09 244.833C167.09 270.068 172.548 293.255 181.415 315.076L331.455 606.285C335.544 614.469 346.461 614.469 350.551 606.285L500.591 315.076C510.139 293.935 514.915 270.063 514.915 244.833C514.905 147.995 437.158 70.2433 340.992 70.2433H341ZM341 305.533C306.897 305.533 278.935 277.571 278.935 243.469C278.935 209.366 306.897 181.404 341 181.404C375.103 181.404 403.064 209.366 403.064 243.469C403.064 277.571 375.103 305.533 341 305.533Z" fill="white"/>
  </svg>
  `,
  locate: `
  <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M21.4999 4.42883C15.437 4.42883 10.5349 9.33075 10.5349 15.4367C10.5349 17.0278 10.8791 18.4897 11.4381 19.8655L20.8981 38.2262C21.1559 38.7422 21.8442 38.7422 22.1021 38.2262L31.5621 19.8655C32.1641 18.5326 32.4652 17.0274 32.4652 15.4367C32.4646 9.33109 27.5627 4.42883 21.4994 4.42883H21.4999ZM21.4999 19.2638C19.3497 19.2638 17.5867 17.5008 17.5867 15.3507C17.5867 13.2005 19.3497 11.4375 21.4999 11.4375C23.6501 11.4375 25.4131 13.2005 25.4131 15.3507C25.4131 17.5008 23.6501 19.2638 21.4999 19.2638Z" fill="white"/>
  </svg>
  `,
  stop: `
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="white" viewBox="0 0 256 256"><path d="M188,88a27.75,27.75,0,0,0-12,2.71V60a28,28,0,0,0-41.36-24.6A28,28,0,0,0,80,44v6.71A27.75,27.75,0,0,0,68,48,28,28,0,0,0,40,76v76a88,88,0,0,0,176,0V116A28,28,0,0,0,188,88Zm12,64a72,72,0,0,1-144,0V76a12,12,0,0,1,24,0v44a8,8,0,0,0,16,0V44a12,12,0,0,1,24,0v68a8,8,0,0,0,16,0V60a12,12,0,0,1,24,0v68.67A48.08,48.08,0,0,0,120,176a8,8,0,0,0,16,0,32,32,0,0,1,32-32,8,8,0,0,0,8-8V116a12,12,0,0,1,24,0Z"></path></svg>
  `
};