import React from 'react';

export interface StatusBadge {
  children: any;
  type: 'warning' | 'info';
}

const style = {
  borderRadius: '10px',
  padding: '8px 12px',
  color: 'white',
  backgroundColor: 'gray',
  width: 'fit-content',
  fontWeight: 'bold'
}

export function StatusBadge(props: StatusBadge) {
  let color = 'gray';

  if(props.type === 'warning') {
    color = '#FF0000';
  }

  const badgeStyle = React.useMemo(() => {
    const s = {...style};

    if(props.type === 'info') {
      s.backgroundColor = '#000AFF';
    } else if(props.type === 'warning') {
      s.backgroundColor = '#FF0000';
    }

    return s;
  }, [props.type]);

  return <div style={badgeStyle}>{props.children}</div>;
}