import { Actor } from "@wargamer/types";
import { ActorAvatar } from '../ActorAvatar';

export interface ActorCardProps {
  actor?: Actor;
}

export function ActorCard(props: ActorCardProps) {

  if(!props.actor) {
    return <></>
  }

  return <div style={{display: 'flex', flexDirection: 'row', gap: 8}}>
    <ActorAvatar key={props.actor.id} actorId={props.actor.id} style='flat' size='large'/>
    <div>
      <h2 style={{flexShrink: 0, minWidth:0, whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{props.actor.name}</h2>
      <div style={{overflow: 'hidden'}}>{props.actor.description}</div>
    </div>
  </div>;
}