import { EventEmitter } from 'events';
import React from 'react';
import { API_HOST } from '../config';

export const RtEvents = new EventEmitter();
let source: EventSource = null as any;

export function useRts(gameId: string, token: string) {

  React.useEffect(() => {

    if(!gameId || !token) {
      return;
    }

    if(source?.OPEN || source?.CONNECTING) {
      return ;
    }
  
    source = new EventSource(`${API_HOST}/game-play/${gameId}/sse?token=${token}`);
  
    source.addEventListener('message', (event => {
      try {
        RtEvents.emit('message', JSON.parse(event.data))
      } catch(err) {}
    }));
  }, [gameId, token]);
}

export function useEvents(cb: Function) {
  const cbRef = React.useRef(cb);
  cbRef.current = cb;

  React.useEffect(() => {
    const listener = (event: any) => {
      cbRef.current(event);
    };
    RtEvents.addListener('message', listener);
    return () => {
      RtEvents.removeListener('message', listener);
    };
  }, []);
}
