import { Action, Actor, GameClock, IActorMainController, IGameMainController} from '@wargamer/types';
import { BattalionActor } from './types';

export class BattalionMain implements IActorMainController<BattalionActor> {
  readonly name = 'battalion';

  constructor(private game: IGameMainController) { }

  validate(asset: BattalionActor) {
    asset.canAttack = false;
    asset.canMove = false;
    asset.canShutdown = false;
    asset.canStop = false;
    asset.canLocate = false;

    return true;
  };

  tick(asset: BattalionActor, clock: GameClock) {

  }

  attack(weapon: BattalionActor, action: Action): void {
    
  }

  move(asset: BattalionActor, action: Action): void {

  }

  stop(asset: BattalionActor): void {

  }

  shutdown(asset: BattalionActor): void {
    
  }

}