import { Action, GameClock, IActorMainController, IGameMainController } from '@wargamer/types';
import { BattleshipActor } from './types';
import { moveEnd, moveStart, moveTick, isActorMoving } from '../shared/move';

export class BattleshipMain implements IActorMainController<BattleshipActor> {
  readonly name = 'battleship';

  constructor(private game: IGameMainController) { }

  validate(actor: BattleshipActor) {
    actor.canAttack = false;
    actor.canMove = true;
    actor.canShutdown = false;
    actor.moveSpeed = actor.speed;
    actor.actorType = 'attack-vehicle';
    return true;
  };

  tick(actor: BattleshipActor, clock: GameClock) {
    if (isActorMoving(actor)) {
      moveTick(actor, this.game.state, () => { });
    }
  }

  attack(weapon: BattleshipActor, action: Action): void {

  }

  move(actor: BattleshipActor, action: Action): void {
    moveStart(actor, action, this.game.state);
    this.game.state.assets.update({
      id: actor.id,
      canStop: true,
    });
  }

  stop(actor: BattleshipActor): void {
    console.log('ending move!');
    moveEnd(actor, this.game.state);
    this.game.state.assets.update({
      id: actor.id,
      canStop: false,
    });
  }

  shutdown(actor: BattleshipActor): void {

  }

}