import { IActorRendererController, IGameState, ActorMapItem, ActorMapState} from '@wargamer/types';
import { LocationActor } from './types';
import { memCreateIcon, createIconKey, IconOptions } from '../shared/icon';
import { Feature } from 'geojson';
import { svgs } from '../shared/svgs';
import { SvgIcon } from '../shared/SvgIcon';

export class LocationRenderer implements IActorRendererController<LocationActor> {
  readonly name = 'location';

  constructor(private state: IGameState) { }

  renderStatus(actor: LocationActor) {
    return <></>;
  }

  renderSvgIcon(asset: LocationActor) {
    if(!asset.icon) {
      return null;
    }
    const svg = svgs[asset.icon].replace('{COLOR}', 'white');
    return <SvgIcon>{svg}</SvgIcon>;
  }

  async renderMapIcon(asset: LocationActor, state: ActorMapState) {
    const team = this.state.teams.get(asset.teamId);

    const iconOptions: IconOptions = {
      backgroundColor: 'rgba(0,0,0,0)',
      color: team?.primaryColor || 'white',
      icon: (svgs[asset.icon] || missileSvg),
      selected: state.selected,
      targeted: state.targeted,
      frame: true
    };

    if(this.state.iconType === 'mil' && asset.milSymbol) {
      iconOptions.icon = '';
      iconOptions.milSymbol = {
        symbol: asset.milSymbol,
        options: { }
      }
    }

    const icon = await memCreateIcon(iconOptions);
    const iconId = createIconKey(iconOptions);

    const feature: Feature = {
      geometry: {
        coordinates: asset.point,
        type: 'Point',
      },
      id: asset.id,
      properties: {
        id: asset.id,
        // img: 
        // heading: item.heading || 0,
        // type: item.weapon ? 'weapon' : 'location',
        // name: item.name,
        icon: iconId
      },
      type: 'Feature'
    }

    const item: ActorMapItem = {
      icons: {
        [iconId]: icon
      },
      actorFeatures: [feature]
    }
 
    return item;
  }
}

const missileSvg = `
<svg width="6" height="20" viewBox="0 0 6 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.90391 14.772C2.90391 14.992 2.72386 15.1719 2.50399 15.1719C2.28396 15.1719 2.10407 14.9918 2.10407 14.772V12.5599C2.10407 12.3399 2.28412 12.16 2.50399 12.16C2.72402 12.16 2.90391 12.34 2.90391 12.5599V14.772ZM3.32001 1.78406L2.39594 0L1.4679 1.99602C1.23184 2.52 1.07986 3.05606 1.00387 3.6H3.96391C3.85191 2.98398 3.636 2.37594 3.32001 1.78406ZM4.05594 13.3802C4.05594 3.98562 4.06037 4.50594 4.04801 4.40008H0.951992V13.3802L0 14.4362V19.1003L1.70809 17.2083H1.93605C1.87212 17.4163 1.83198 17.6643 1.83198 17.9284C1.83198 18.6684 2.50398 19.6644 2.50398 19.6644C2.50398 19.6644 3.17995 18.6684 3.17995 17.9284C3.17995 17.6644 3.13998 17.4165 3.07192 17.2083H3.29989L5.01192 19.1003V14.4362L4.05594 13.3802Z" fill="black"/>
</svg>
`;