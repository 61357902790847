import { CSSProperties } from "react";

export interface PanelProps {
  title: any;
  titleStyle?: CSSProperties;
  children: any;
  style?: CSSProperties;
}

export function Panel(props: PanelProps) {

  return <div
    style={{
      backgroundColor: '#3E3F46',
      flex: 1,
      ...props.style,
      padding: 0,
      border: 'initial'
    }}
  >
    <div style={{
      height: 38,
      paddingLeft: '8px',
      paddingRight: '8px',
      fontWeight: 'bold',
      color: '#fff',
      border: '1px solid #7D736B',
      backgroundColor: 'black',
      alignItems: 'center',  
      textTransform: 'uppercase',  
      justifyContent: 'space-between',
      flexDirection: 'row',
      ...props.titleStyle
    }}>
      {props.title}
    </div>
    <div
      style={{padding: 16, ...props.style, flex: 1, height: 'initial'}}
    >
      {props.children}
    </div>
  </div>
}