import { useGameSession, useGameState } from './GameStateProvider';
import { useCollectionValue } from './useCollectionValues';
import { Team } from '@wargamer/types';

export function useTeam(teamId: string) {
  const state = useGameState();
  return useCollectionValue(state?.teams, teamId);
}

export function useMyTeam() {
  const session = useGameSession();
  const state = useGameState();
  return useCollectionValue(state?.teams, session.playerTeam) || {} as Team;
}