import { EventEmitter } from 'events';

export class Single<T> {
  private _events = new EventEmitter();
  private _item: T = {} as any;

  get(): T {
    return this._item;
  }

  update(changes: Partial<T>) {
    if(this._item) {
      this._item = {
        ...this._item,
        ...changes,
      };
    } else {
      this._item = {...changes as any};
    }
    this._events.emit('event', {item: this._item, action: 'update'});
  }

  on(fn: (event: {item: T, action: 'update'}) => void) {
    this._events.on('event', fn);
  }
  off(fn: (event: {item: T, action: 'update'}) => void) {
    this._events.off('event', fn);
  }
}