import { IActorRendererController, IGameState, ActorMapState} from '@wargamer/types';
import { BattalionActor } from './types';
import { svgs } from '../shared/svgs';
import { SvgIcon } from '../shared/SvgIcon';


export class BattalionRenderer implements IActorRendererController<BattalionActor> {
  readonly name = 'battalion';

  constructor(private state: IGameState) { }

  renderStatus(actor: BattalionActor) {
    return <></>;
  }

  async renderMapIcon(asset: BattalionActor, state: ActorMapState) {
    return null;
  }

  renderSvgIcon(asset: BattalionActor) {
    if(!asset.icon) {
      return null;
    }
    const svg = svgs[asset.icon].replace('{COLOR}', 'white');
    return <SvgIcon>{svg}</SvgIcon>;
  }
  
}