import { ActionButton, Actions } from '../ActionButton';

export interface ActorActionsProps {
  canAttack?: boolean;
  canDeselect?: boolean;
  canLocate?: boolean;
  canMove?: boolean;
  canShutdown?: boolean;
  canStop?: boolean;
  onAction: (action: Actions) => void;
}

export function ActorActions(props: ActorActionsProps) {
  return <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
    <h2>Actions</h2>
    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 8, minWidth: '92px'}}>
      {props.canDeselect ? <ActionButton action="deselect" onClick={props.onAction}/> : null}
      {props.canLocate ? <ActionButton action="locate" onClick={props.onAction}/> : null}
      {props.canMove ? <ActionButton action="move" onClick={props.onAction}/> : null}
      {props.canAttack ? <ActionButton action="attack" onClick={props.onAction}/> : null}
      {props.canAttack ? <ActionButton action="find-attack" onClick={props.onAction}/> : null}  
      {props.canShutdown ? <ActionButton action="shutdown" onClick={props.onAction}/> : null}
      {props.canStop ? <ActionButton action="stop" onClick={props.onAction}/> : null}
      {/* <ActionButton action="find-actor" onClick={props.onAction}/> */}
    </div>
  </div>;
}