import axios from 'axios';
import { ChatMessage } from '@wargamer/types';
import { API_HOST } from '../config';

export async function sendChat(chatMessage: ChatMessage, gameId: string, token: string) {
  return axios.post(`${API_HOST}/game-play/${gameId}/send-message`, chatMessage, {
    headers: {
      token
    }
  });
}
