export interface ModalViewProps {
  children: any;
  title: any;
}

export function ModalView(props: ModalViewProps) {
  return <div style={{
    backgroundColor: 'white',
    color: 'black',
    borderRadius: 10,
    overflow: 'hidden',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 332,
    fontWeight: 400,
    fontSize: 16,
    width: 755
  }}>
    <div style={{
      display: 'flex',
      backgroundColor: '#D9D9D9',
      fontWeight: 'bold',
      alignItems: 'center',
      justifyContent: 'center',
      height: 47
    }}>
      {props.title}
    </div>
    <div style={{flexGrow: 1, display: "flex", padding: "10px 20px 10px 20px"}}>
      {props.children}
    </div>
  </div>
}