import paper from 'paper';
import { MilSymbols, SymbolOptions } from './web.APP_TARGET';


export interface IconOptions {
  selected?: boolean;
  targeted?: boolean;
  backgroundColor: string;
  color: string;
  icon: string;
  frame: boolean;
  opacity?: number;
  milSymbol?: {
    symbol: string,
    options: SymbolOptions
  };
}

const cache = new Map<string, ImageData>();
export function memCreateIcon(options: IconOptions) {
  const key = createIconKey(options);

  const value = cache.get(key);
  if(value) {
    return value;
  }

  const image = createIcon(options);
  cache.set(key, image);

  return image;
}

const map = new Map<string, string>();

export function createIconKey(options: IconOptions) {
  const key =  [
    (options?.color || false).toString(),
    (options?.backgroundColor || false).toString(),
    (options?.selected || false).toString(),
    (options?.targeted || false).toString(),
    (options?.icon || false).toString(),
    (options?.opacity || 1).toString(),
    (JSON.stringify(options.milSymbol) || false).toString(),
  ].join(':');

  let cacheKey = map.get(key);

  if(!cacheKey) {
    cacheKey = Math.random().toString();
    map.set(key, cacheKey);
  }

  return cacheKey;
}

export function createIcon(options: IconOptions) {
  const canvas = document.createElement('canvas');

  const iconSize = 40;

  canvas.style.width = `${iconSize}px`;
  canvas.style.height = `${iconSize}px`;
  canvas.style.position = 'fixed';
  canvas.style.border = '1px solid red';
  canvas.style.top = '100px';
  canvas.style.left = '100px';
  canvas.style.zIndex = '2000';
  canvas.style.position = 'fixed';
  
  // document.body.appendChild(canvas);

  paper.setup(canvas);

  const group = new paper.Group();
  group.bounds.set([0,0], [0,iconSize], [iconSize,iconSize], [iconSize,0]);
  const center = new paper.Point(iconSize / 2, iconSize / 2);
  const backgroundColor = options.color;
  let iconColor = options.color;

  if((options.selected || options.frame) && !options.milSymbol) {
    iconColor = 'rgb(255,255,255)';

    group.importSVG(selectedSquareSvg.replaceAll('{COLOR}', backgroundColor), (item: paper.Item) => {
      item.position = center;
      if(options.selected) {
        item.opacity = .9;
        item.shadowColor = new paper.Color('black');
        item.shadowBlur = 4
        item.shadowOffset = new paper.Point(2,2);
      }
    });
  }

  if(options.icon) {
    let svg = options.icon.replace('{COLOR}', iconColor).replaceAll('{SIZE}', '32');

    svg = svg.replace('<svg', `<svg width="32" height="32"`);
    
    group.importSVG(svg, (item: paper.Item) => {
      item.position = center;
    });
  } else {

    
    const canvas = new MilSymbols.Symbol(options.milSymbol.symbol,{
      size:24,
      colorMode: {
        Civilian: options.color,
        Friend: options.color,
        Hostile: options.color,
        Neutral: options.color,
        Unknown: options.color
      },
      ...options.milSymbol.options,
    }).asCanvas();
  
    const raster = new paper.Raster(canvas);
    raster.position = center;
    group.addChild(raster);
  }


  if(options.targeted) {
    group.importSVG(targetSvg, (item: paper.Item) => {
      item.position = center;
    });
  }
  group.opacity = options.opacity || 1;
  const data = group.rasterize().getImageData(null as any);

  group.remove();
  // canvas.remove();

  return data;
}


const selectedSquareSvg = `
<svg width="40" height="40" viewBox="0 0 40 40"  fill="{COLOR}" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="4" width="32" height="32" stroke="white" stroke-width=".1"/>
</svg>
`;

const targetSvg = `
<svg width="32" height="32" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.6198 23.4801C29.3765 23.3365 29.0856 23.2967 28.8128 23.37C28.5398 23.4433 28.308 23.6238 28.1699 23.8701C27.2242 25.4915 25.905 26.8636 24.3224 27.8725C22.7396 28.8813 20.9389 29.4976 19.0699 29.6701V28.67C19.0699 28.2877 18.8658 27.9345 18.5348 27.7433C18.2039 27.5524 17.7957 27.5524 17.4647 27.7433C17.1338 27.9345 16.9297 28.2877 16.9297 28.67V29.6701C14.2032 29.4166 11.6508 28.2185 9.71354 26.283C7.77636 24.3478 6.57585 21.7964 6.31956 19.0703H7.31961C7.70194 19.0703 8.05515 18.8663 8.24633 18.5353C8.43751 18.2043 8.4375 17.7962 8.24633 17.4652C8.05517 17.1343 7.70196 16.9302 7.31961 16.9302H3.06985C2.68753 16.9302 2.33432 17.1342 2.14314 17.4652C1.95196 17.7962 1.95197 18.2043 2.14314 18.5353C2.3343 18.8663 2.68751 19.0703 3.06985 19.0703H4.18965C4.44453 22.3654 5.86808 25.4611 8.20407 27.7989C10.5403 30.1368 13.6349 31.5628 16.9299 31.8202V32.9301C16.9299 33.3124 17.1339 33.6656 17.4649 33.8568C17.7959 34.0479 18.204 34.0479 18.535 33.8568C18.8659 33.6656 19.07 33.3124 19.07 32.9301V31.83C21.3145 31.652 23.4824 30.9311 25.3863 29.7289C27.2905 28.5269 28.874 26.8801 30 24.93C30.1445 24.6881 30.1857 24.398 30.1143 24.1253C30.0429 23.8526 29.8646 23.6202 29.6199 23.4801L29.6198 23.4801Z" fill="black"/>
<path d="M32.9301 16.9298H31.8103C31.551 13.6383 30.1253 10.5473 27.7895 8.21384C25.4538 5.88038 22.3621 4.45676 19.0701 4.20026V3.07034C19.0701 2.68802 18.866 2.3348 18.5351 2.14362C18.2041 1.95244 17.7959 1.95246 17.465 2.14362C17.134 2.33479 16.9299 2.688 16.9299 3.07034V4.19014C14.6884 4.36592 12.5227 5.08276 10.6191 6.27947C8.71546 7.47591 7.13041 9.11644 5.99993 11.0602C5.84145 11.3862 5.85848 11.7705 6.04552 12.0808C6.23284 12.3915 6.56435 12.5859 6.92661 12.598C7.28888 12.6101 7.63275 12.4376 7.84011 12.1401C8.78328 10.5199 10.1005 9.14884 11.6821 8.14169C13.2632 7.13425 15.0628 6.52014 16.93 6.35034V7.35039C16.93 7.73244 17.134 8.08565 17.465 8.27683C17.796 8.46801 18.2041 8.46799 18.5351 8.27683C18.866 8.08566 19.0701 7.73245 19.0701 7.35039V6.35034C21.7966 6.60385 24.349 7.80194 26.2863 9.73743C28.2235 11.6726 29.424 14.224 29.6803 16.9501H28.6802C28.2979 16.9501 27.9447 17.1542 27.7535 17.4851C27.5623 17.8161 27.5623 18.224 27.7535 18.5552C27.9447 18.8862 28.2979 19.0902 28.6802 19.0902H32.9402C33.3225 19.0902 33.6757 18.8862 33.8669 18.5552C34.0581 18.224 34.0581 17.8161 33.8669 17.4851C33.6757 17.1542 33.3225 16.9501 32.9402 16.9501L32.9301 16.9298Z" fill="black"/>
<path d="M18 14.8C17.7163 14.8 17.4441 14.9129 17.2433 15.1134C17.0428 15.3142 16.9299 15.5864 16.9299 15.8701V16.93H15.87C15.4877 16.93 15.1345 17.1341 14.9433 17.4651C14.7521 17.796 14.7521 18.2042 14.9433 18.5351C15.1345 18.8661 15.4877 19.0702 15.87 19.0702H16.9299V20.1301C16.9299 20.5124 17.134 20.8656 17.465 21.0568C17.7959 21.248 18.2041 21.248 18.535 21.0568C18.866 20.8656 19.0701 20.5124 19.0701 20.1301V19.0702H20.13C20.5123 19.0702 20.8655 18.8661 21.0567 18.5351C21.2479 18.2042 21.2478 17.796 21.0567 17.4651C20.8655 17.1341 20.5123 16.93 20.13 16.93H19.0701V15.8701C19.0701 15.5864 18.9572 15.3142 18.7567 15.1134C18.5559 14.9129 18.2837 14.8 18 14.8Z" fill="black"/>
</svg>
`;

