import { ActorMainClassConstructor, ActorRendererClassConstructor } from '@wargamer/types';
import { MissileMain, MissileRenderer } from './Missile';
import { LocationMain, LocationRenderer } from './Location';
import { BattalionMain, BattalionRenderer } from './Battalion';
import { SiloMain, SiloRenderer } from './Silo';
import { BattleshipMain, BattleshipRenderer } from './Battleship';

export * from './Missile';
export * from './Location';
export type { BattalionActor } from './Battalion';
export type { SiloActor } from './Silo'
export type { BattleshipActor } from './Battleship';

export const actorMainList: ActorMainClassConstructor[] = [
  BattalionMain,
  MissileMain,
  LocationMain,
  SiloMain,
  BattleshipMain,
];

export const actorRendererList: ActorRendererClassConstructor[] = [
  BattalionRenderer,
  MissileRenderer,
  LocationRenderer,
  SiloRenderer,
  BattleshipRenderer,
];