import { IGameState, IActorRendererController, Actor, ActorRendererClassConstructor, ActorMapItem, ActorMapState, Action } from '@wargamer/types';
import { Feature, Polygon } from 'geojson';

export class ActorRendererCollection implements IActorRendererController<Actor> {

  readonly name = 'RendererAssetHandler';

  private readonly classMap: {[name: string]: IActorRendererController<Actor>} = {};

  constructor(
    private state: IGameState,
    private classList: ActorRendererClassConstructor[],
  ) {
    this.classList.forEach((cls) => {
      const instance = new cls(state);
      if(!instance.name) {
        throw new Error(`${cls} does not have a valid name property.`);
      }

      if(this.classMap[instance.name]) {
        throw new Error(`${cls['name']} and ${this.classMap[instance.name]['constructor']['name']} cannot have the same names.`);
      }

      this.classMap[instance.name] = instance;
    });
  }

  get(name: string) {
    return this.classMap[name];
  }

  renderStatus(actor: Actor) {
    const actorHandler = this.get(actor.actorClass);
    if(!actorHandler) {
      throw new Error(`actor with id ${actor.id} and class ${actor.actorClass} has no registered class handler`);
    }

    return actorHandler.renderStatus(actor);
  }

  renderSvgIcon(actor: Actor) {
    const actorHandler = this.get(actor.actorClass);
    if(!actorHandler) {
      console.log(this);
      throw new Error(`actor with id ${actor.id} and class ${actor.actorClass} has no registered class handler`);
    }

    return actorHandler.renderSvgIcon(actor);
  }

  renderAttackForm(actor: Actor, action: Action, onChange:(action: Action) => void) {
    const actorHandler = this.get(actor.actorClass);
    if(!actorHandler?.['renderAttackForm']) {
      return null;
    }

    return actorHandler?.['renderAttackForm'](actor, action, onChange);
  }

  private differ = new Differ();
  private _errorCache = new Set();
  async renderMapIcon(actor: Actor, state: ActorMapState): Promise<ActorMapItem> {
    const actorHandler = this.get(actor.actorClass);
    if(!actorHandler) {
      throw new Error(`actor with id ${actor.id} and class '${actor.actorClass}' (name: ${actor.name}) has no registered class handler`);
    }

    // return actorHandler.renderMapIcon(actor, state);

    const forceNew = state.selected || state.targeted || actor.status === 'en-route' || state.forcedRerender;
    const item = await this.differ.cache(actor, async (actor) => actorHandler.renderMapIcon(actor, state), forceNew);

    if(!item?.actorFeatures?.[0]?.geometry?.['coordinates']?.length) {
      if(item?.actorFeatures?.[0]?.geometry) {
        if(this._errorCache.has(actor.id)) return null;
        // console.warn(actor.id,actor.name, 'did not return proper geometry');
        this._errorCache.add(actor.id);
      }
      return null;
    }

    return item;
  }

  getRangeRingFeatures(actor: Actor) {

    const actorHandler = this.get(actor.actorClass);

    if(!actorHandler?.['getRangeRingFeatures']) {
      return null;
    }

    const feature = actorHandler?.['getRangeRingFeatures'](actor);

    return feature as Feature<Polygon>;
  }

}

class Differ {
  private known = new WeakSet<object>();
  private known2 = new WeakMap<object, object>;
  private map = new Map<string, WeakRef<object>>();
  inputArray<T extends object>(collection: T[]): T[] {
    return collection.filter(item => {
      return this.inputObject(item);
    }).filter(i => i);
  }

  inputObject<T extends object>(object: T): T | null {
    if(!object) {
      return null;
    }

    if(this.known.has(object)) {
      return null;
    }

    this.known.add(object);

    return object;
  }

  setValue<T extends object>(key: string, object: T) {
    if(!this.map.get(key) || this.map.get(key)?.deref() !== object) {
      const ref = new WeakRef(object);
      this.map.set(key, ref);
      return object;
    }
    
    return null;
  }

  cache<T extends object>(subject: T, onChange: (subject: T) => any, forceNew: boolean): any {
    let value = this.known2.get(subject);

    if(!value || forceNew) {
      value = onChange(subject);
      this.known2.set(subject, value);
    }

    return value;
  }
}