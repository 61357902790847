import React, { CSSProperties } from "react";
import { ModalView } from './ModalView';
import { useModal } from '../hooks';

export interface AlertOptions {
  title: string;
  message: any;
}

export function useAlertModal() {
  const modal = useModal();
  const open = (alert: AlertOptions) => {
    modal.open(<AlertModal
      alert={alert}
      onClose={modal.close}
    />);
  }

  return {
    open,
    close: modal.close,
    isOpen: modal.isOpen,
    root: modal.root,
  };
}

interface AlertModalProps {
  alert: AlertOptions;
  onClose: () => void;
}

const buttonStyle: CSSProperties = {
  fontWeight: 'bold',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  width: 117,
  height: 40,
  borderRadius: .5,
  backgroundColor: '#D9D9D9'
};

function AlertModal(props: AlertModalProps) {

  return <ModalView title={props.alert.title}>
    <div style={{ display: 'flex', flexDirection: 'column', gap: 24, padding: 24}}>
      <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1, textAlign: 'center', fontWeight: 'bold'}}>
      {props.alert.message}
      </div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <button style={buttonStyle} onClick={props.onClose}>Close</button>
      </div>
    </div>
  </ModalView>;
}