import React, { CSSProperties, useState } from "react";
import { ModalView } from './ModalView';
import {  Actor } from '@wargamer/types';
import { usePopover } from '../hooks';
import { useGameSession } from '../hooks';

export interface OpenActionPopoverOptions {
  teamId: string;
  focusedActor?: Actor;
  target?: Actor;
  position: [number, number];
  onAttack: () => void;
  onMove: () => void;
}

export function useActionPopover() {
  const popover = usePopover();

  const open = (options: OpenActionPopoverOptions) => {
    popover.open({
      child: <Action {...options}/>,
      position: options.position
    });
  };

  return {
    ...popover,
    open,
  }
}

interface ActionProps {
  teamId: string;
  focusedActor?: Actor;
  target?: Actor;
  position: [number, number];
  onAttack: () => void;
  onMove: () => void;
}

function Action(props: ActionProps) {
  return <div>
    {!props?.target ? <button onClick={props.onMove}>Move Here</button> : null}
    {props?.target && props.focusedActor ? <button onClick={props.onAttack}>Attack</button> : null}
  </div>;
}