import { Actor } from "@wargamer/types";

const cache = new Map<string, string[]>();

export function getActorParents(actor: Actor, allActors: Actor[]) {
  const actorId = actor.id;
  if(cache.has(actorId)) {
    const parents = cache.get(actorId);
    return allActors.filter((a) => parents.includes(a.id));
  }

  actor = allActors.find(a => a.id === actor?.parentActor);
  let actors: Actor[] = [];

  while(actor) {
    actors.push(actor);
    if(actor?.parentActor) {
      actor = allActors.find(a => a.id === actor?.parentActor);
    } else {
      actor = undefined;
    }
  }

  cache.set(actorId, actors.map(a => a.id));

  return actors;
}
