import { getDistance, calculateBlast } from '@wargamer/lib/dist/utils';
import { IGameState, Actor, Weapon } from "@wargamer/types";
import { MissileActor } from "./types";
import { filterActors } from './getActorsInBlastZone';

export function calculateCasualties(
  weapon: MissileActor,
  target: [number, number],
  state: IGameState,
  detonationType: 'air' | 'ground'
) {

  const kt = weapon.kilotons || 1;
  detonationType = detonationType || 'ground';

  let data = [
    {
      radius: calculateBlast(kt, detonationType, 'RadiationRadius'),
      fatalityRate: .98,
      injuryRate: .02,
    },{
      radius: calculateBlast(kt, detonationType, 'FireballRadius'),
      fatalityRate: .75,
      injuryRate: .2,
    },{
      radius: calculateBlast(kt, detonationType, 'HeavyBlastDamage'),
      fatalityRate: .5,
      injuryRate: .5,
    },{
      radius: calculateBlast(kt, detonationType, 'ModerateBlastDamage'),
      fatalityRate: .25,
      injuryRate: .60,
    },{
      radius: calculateBlast(kt, detonationType, 'LightBlastDamage'),
      fatalityRate: .05,
      injuryRate: .12,
    },
  ];

  if (weapon.blastRadius) {
    data = [{
      radius: weapon.blastRadius,
      fatalityRate: 1,
      injuryRate: 1,
    }]
  }

  const casualtyItems: CasualtyItem[] = [];

  filterActors((item) => {
    if(item.actor.destroyable !== true || item.actor.status === 'destroyed' || item.actor.status === 'expended' || item.actor.id === weapon.id) {
      return false;
    }

    if(!item.pos) {
      return false;
    }

    const radius = getDistance(target, item.pos);
    let currentPeopleEffected = 0;
    let populationFatalities = 0;

    let c: CasualtyItem = {
      target: item.actor.id,
      destroyed: false,
      casualties: 0,
      fatalities: 0,
      injuries: 0,
    };


    data.forEach((data, i, a) => {
      if(radius >= data.radius) {
        return null;
      }

      if(i <= 2 ) {
        c.destroyed = true;
        if(item.actor.population) {
          populationFatalities = item.actor.population;
        }
      }

      if(item.actor.populationDensity) {
        const area = (Math.PI * (Math.pow(data.radius / 1000, 2)));
        const peopleEffected = (area * item.actor.populationDensity) - currentPeopleEffected;
        currentPeopleEffected+=peopleEffected;

        c.fatalities += peopleEffected * data.fatalityRate;
        c.injuries += peopleEffected * data.injuryRate;
      }
    });

    c.fatalities += populationFatalities;
    c.casualties = c.fatalities + c.injuries;

    if(!c.casualties && !c.destroyed) {
      return false;
    }

    casualtyItems.push(c);
    return true;
  }, state);

  const casualtySum: Casualties = {
    items: casualtyItems,
    casualties: casualtyItems.reduce((a,b) => a + b.casualties, 0),
    fatalities: casualtyItems.reduce((a,b) => a + b.fatalities, 0),
    injuries: casualtyItems.reduce((a,b) => a + b.injuries, 0),
  }

  return casualtySum;
}

export interface Casualties {
  items: Array<CasualtyItem>;
  injuries: number;
  fatalities: number;
  casualties: number;
}

export interface CasualtyItem {
  destroyed: boolean;
  target: string;
  injuries: number;
  fatalities: number;
  casualties: number;
}
