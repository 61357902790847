import { getDistance, getFlightTime, getHeading, calculateBlast } from '@wargamer/lib/dist/utils';
import { Action, Actor, GameClock, IActorMainController, IGameMainController, IGameState} from '@wargamer/types';
import { MissileActor, MissileFireAction } from './types';
import { moveEnd, moveStart, moveTick, isActorMoving, getMoveCurrent } from '../shared/move';
import { getActorsInBlastZone } from './getActorsInBlastZone';
import { calculateCasualties } from './calculateCasualties';

export class MissileMain implements IActorMainController<MissileActor> {
  readonly name = 'missile';

  constructor(private game: IGameMainController) { }

  validate(actor: MissileActor) {
    actor.canAttack = true;
    actor.canMove = false;
    actor.canShutdown = false;
    actor.moveSpeed = actor.speed;
    actor.actorType = 'weapon';
    return true;
  };

  tick(actor: MissileActor, clock: GameClock) {
    if (isActorMoving(actor)) {
      moveTick(actor, this.game.state, () => { 
        this.detonateWeapon(actor.id);
      });
    }
  }

  private roleRealizability(actor: MissileActor) {
    const r = (actor.reliability || 1);
    const v = Math.random();
    
    if(r - v < 0) {
      this.game.state.assets.update({
        id: actor.id,
        status: 'destroyed',
        canAttack: false
      });

      this.game.addGameUpdate({
        actorId: actor.id,
        teamId: actor.teamId,
        type: 'aar',
        message: `Missile failed during launch`,
      })

      throw new Error(`Missile failed during launch.`);
    }

    return true;
  }

  attack(actor: MissileActor, action: MissileFireAction): void {
    if(actor.status !== 'available') {
      throw new Error('Weapon is not available for another attack.');
    }

    this.roleRealizability(actor);

    if(moveStart(actor, action, this.game.state)) {
      this.game.state.assets.update({
        id: actor.id,
        canAttack: false,
        canShutdown: true,
      });

      const weaponNames = this.getActorParentsName(actor);

      if(action.targetedActorId ) {
        const target = this.game.state.assets.get(action.targetedActorId);
        const targetNames = this.getActorParentsName(target);

        this.game.addGameUpdate({
          teamId: actor.teamId,
          message: `${weaponNames.forceName} attacks ${targetNames.baseName} with Missile.`,
          type: 'sit-rep',
          actorId: actor.id,
        });
      } else {
        this.game.addGameUpdate({
          teamId: actor.teamId,
          message: `${weaponNames.forceName} launches missile.`,
          type: 'sit-rep',
          actorId: actor.id,
        });
      }

    }
  }

  private getActorParentsName(actor: Actor) {
    let baseActor = actor.parentActor ? this.game.state.assets.get(actor.parentActor) : null;
    let forceActor = baseActor.parentActor ? this.game.state.assets.get(baseActor.parentActor) : null;

    return {
      baseName: baseActor?.name || '',
      forceName: forceActor?.name || ''
    }
  }

  move(actor: MissileActor, action: Action): void {

  }

  stop(actor: MissileActor): void {
    moveEnd(actor, this.game.state);
    this.game.state.assets.update({
      id: actor.id,
      canAttack: false,
      canShutdown: false,
      canMove: false,
      status: 'decommissioned'
    });
  }

  shutdown(actor: MissileActor): void {
    moveEnd(actor, this.game.state);
    this.game.state.assets.update({
      id: actor.id,
      canAttack: false,
      canShutdown: false,
      canMove: false,
      status: 'decommissioned'
    });
  }

  private detonateWeapon(weaponId: string,) {
    const weapon = this.game.state.assets.get<MissileActor>(weaponId);
    const target = weapon.point;

    const casualties = calculateCasualties(weapon, target, this.game.state, weapon.detonationType);

    this.game.state.assets.update({
      id: weaponId,
      status: 'expended',
      canAttack: false,
      ['casualties' as any]: casualties
    });

    const destroyedActorIds = casualties.items.filter(i => i.destroyed).map(i => i.target);

    // const destroyedActors = getActorsInBlastZone(weapon, target, this.game.state);

    destroyedActorIds.forEach(id => {
      const l = this.game.state.assets.get(id);

      this.game.state.assets.update<MissileActor>({
        id: l.id,
        status: 'destroyed',
        destroyedBy: weaponId
      });

      if(l.destroyable && l.actorType !== 'weapon') {
        this.game.addGameUpdate({
          message: `${l.name} destroyed.`,
          teamId: l.teamId,
          type: 'aar',
          actorId: l.id
        });
      }
    });

  }

}