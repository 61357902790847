import React from "react";

export interface OpenPopoverOptions {
  child: JSX.Element;
  position: [number, number];
}

export function usePopover() {
  const [root, setRoot] = React.useState<JSX.Element | null>(null);

  const open = (options: OpenPopoverOptions) => {
    setRoot(<>
      <div
        onClick={close}
        style={{
          zIndex: 1000,
          position: 'fixed',
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          backgroundColor: 'rgba(89, 89, 89, 0.1)'
      }}></div>
      <div
        style={{
          zIndex: 1001,
          position: 'fixed',
          left: options.position[0],
          top: options.position[1],
          display: 'flex',
          alignItems: "center",
          justifyContent: 'center',
          backgroundColor: 'white',
          color: 'black'
      }}>
        {options.child}
      </div>
    </>)
  }

  const close = () => {
    setRoot(null);
  }

  return {root, open, close, isOpen: root ? true : false};
}