import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import mapboxgl from '../lib/mapbox';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import React from 'react';
import { Feature, FeatureCollection, Point } from 'geojson';
import { Actor, ActorMapItem, ActorMapState } from '@wargamer/types';
import { MapboxHelper, MapClickEvent } from './MapboxHelper';
import './Map.scss';
import { createCircleFromPoint } from '@wargamer/lib/src/utils';
import { useActorRenderer, useGameSession } from '../hooks';
import { MapboxLayerControlButton } from './MapboxLayerControlButton';
import { getDefaultMapLayerSettings, useMapLayersModal } from './MapLayersModal';
export type { MapClickEvent } from './MapboxHelper';

mapboxgl.accessToken = 'pk.eyJ1IjoibGlnaHRob3JzZS1vcHMiLCJhIjoiY2o4NjQ5eGRrMHMxNTJxbHNvZ2V0OHpzbiJ9.Tv0RsbBSVz257Wg5hu5AbA';

export interface MapItem {
  type: string;
  id: string;
  point: [number, number];
  selected: boolean;
  targeted: boolean;
  location: boolean;
  weapon: boolean;
  teamId: string;
  mushroom?: boolean;
  heading?: number;
  detonationType?: 'ground' | 'air';
  kilotons?: number;
  color: string;
  range?: number;
  name?: string;
}

export interface MapAnimatedItem {
  id: string;
  type: string;
  from: [number, number];
  to: [number, number];
  departTime: number;
  arriveTime: number;
}

export interface MapProps {
  actors: Actor[];
  time?: number;
  allowDrawing?: boolean;
  onDrawChanges: (features: FeatureCollection) => void;
  mapDrawFeatures: FeatureCollection;
  onClick: (event: MapClickEvent) => void;
  renderMapIcon: (actor: Actor, state: ActorMapState) => Promise<ActorMapItem>;
  selectedActor?: string;
  targetedActor?: string;
  selectMode?: 'default' | 'target' | 'move';
  locateActor?: string;
  onCancelAction: () => void;
}

const mapStyles = {
  'default': 'mapbox://styles/mapbox/streets-v12',
  'satellite': 'mapbox://styles/mapbox/satellite-streets-v12'
}

export function Map(props: MapProps) {
  const elementRef = React.useRef<HTMLDivElement>();
  const [loaded, setLoaded] = React.useState(false);
  const mapRef = React.useRef<mapboxgl.Map>();
  const [points, setPoints] = React.useState<Feature[]>([]);
  const [rings, setRings] = React.useState<Feature[]>([]);
  const [rangeRings, setRangeRings] = React.useState<Feature[]>([]);
  const onClickRef = React.useRef(props.onClick);
  onClickRef.current = props.onClick || (() => {});
  const actorsRef = React.useRef<Actor[]>([]);
  actorsRef.current = props.actors || [];
  const mapHelper = React.useRef<MapboxHelper>();
  const renderer = useActorRenderer();
  const [layerSettings, setLayerSettings] = React.useState(getDefaultMapLayerSettings);
  const layerSettingsRef = React.useRef(layerSettings);
  layerSettingsRef.current = layerSettings;
  const layersModal = useMapLayersModal();
  const mapThemeRef = React.useRef('');
  const session = useGameSession();

  React.useEffect(() => {
    if(!props.locateActor || !mapRef.current) {
      return;
    }

    const source = mapRef.current.getSource('items') as any;
    const data = source._data as FeatureCollection;
    const [actorId] = props.locateActor?.split(':') || [''];
    const item = data.features.find(f => f.id === actorId);
    if(item) {
      const point = item.geometry as Point;
      mapRef.current.flyTo({center: point.coordinates as any});
    }
    
  }, [props.locateActor]);

  React.useEffect(() => {
    if(!elementRef.current) {
      return;
    }

    setLoaded(false);

    mapRef.current = new mapboxgl.Map({
      container: elementRef.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [-35, 40],
      zoom: 1
    });
    mapThemeRef.current = 'mapbox://styles/mapbox/streets-v12';

    mapHelper.current = new MapboxHelper(
      mapRef.current,
      props.renderMapIcon,
      (e) => onClickRef.current(e)
    );

    const map = mapRef.current;

    if(props.allowDrawing) {
      const Draw = new MapboxDraw({
        controls: {
          trash: true,
          line_string: true,
          combine_features: false,
          point: false,
          polygon: false,
          uncombine_features: false,
        }
      });

      const layersButton = new MapboxLayerControlButton(() => {
        layersModal.open({
          settings: layerSettingsRef.current,
          onChange: (settings) => setLayerSettings(settings)
        });
        console.log('clicked');
      });

      map.addControl(layersButton, "top-right");
      map.addControl(Draw, 'top-right');

      const onDrawUpdate = () => {
        props.onDrawChanges(Draw.getAll());
      }
      
      map.on('draw.update', onDrawUpdate);
      map.on('draw.delete', onDrawUpdate);
      map.on('draw.create', onDrawUpdate);
    }

    mapRef.current.on('load', () => {
      mapHelper.current.addAllLayers();
      setLoaded(true);
    });

    return () => {
      if(mapRef.current) {
        mapRef.current?.remove();
        mapRef.current = null as any;
      }
    }

  }, [elementRef.current]);

  React.useEffect(() => {
    if(!loaded) return;
    const styleUrl = mapStyles[layerSettings.mapStyle];
    if(mapThemeRef.current === styleUrl) return;

    mapRef.current.once('styledata', () => {
      console.log('adding all layers');
      mapHelper.current.addAllLayers();
      mapHelper.current.rerender();
      setRangeRings(r => [...r]);
    });

    mapRef.current.setStyle(styleUrl);
    mapThemeRef.current = styleUrl;
  }, [loaded, layerSettings.mapStyle]);

  React.useEffect(() => {
    session.gameState.iconType = layerSettings.iconStyle;
    console.log(session.gameState.iconType);
    if(loaded) {
      mapHelper.current.resetImageDiffer();
      mapHelper.current.rerender();
    }

  }, [loaded, layerSettings.iconStyle])

  React.useEffect(() => {
    if(!props.actors?.length || !loaded) {
      return;
    }

    mapHelper.current?.update(props.actors, props.selectedActor, props.targetedActor)

  }, [props.actors, loaded, props.selectedActor, props.targetedActor]);

  React.useEffect(() => {
    if(!props.actors?.length || !loaded) {
      return;
    }

    const actor = props.actors.find(a => a.id === props.selectedActor);

    if(!actor) {
      return setRangeRings([]);
    }

    const ring = renderer.getRangeRingFeatures(actor);

    if(!ring) {
      return setRangeRings([]);
    } else {
      return setRangeRings([ring]);
    }

  }, [props.actors, loaded, props.selectedActor, renderer]);

  React.useEffect(() => {
    if(!loaded) {
      return
    }

    const source = mapRef.current?.getSource('items') as mapboxgl.GeoJSONSource;

    if(!source) {
      console.log('no source found!');
      return;
    }

    source.setData({
      type: 'FeatureCollection',
      features: points
    });

  }, [loaded, points]);

  React.useEffect(() => {
    if(!loaded) {
      return
    }

    const source = mapRef.current?.getSource('ring_items') as mapboxgl.GeoJSONSource;

    if(!source) {
      console.log('no source found!');
      return;
    }

    source.setData({
      type: 'FeatureCollection',
      features: rings
    });

  }, [loaded, rings]);


  React.useEffect(() => {
    if(!loaded) {
      return
    }

    const source = mapRef.current?.getSource('range_rings') as mapboxgl.GeoJSONSource;

    if(!source) {
      console.log('no source found!');
      return;
    }

    source.setData({
      type: 'FeatureCollection',
      features: rangeRings
    });

  }, [loaded, rangeRings]);

  React.useEffect(() => {
    if(!loaded) {
      return
    }

    // if(props.allowDrawing) {
    //   return;
    // }

    const source = mapRef.current?.getSource('draw_features') as mapboxgl.GeoJSONSource;

    if(!source) {
      console.log('no source found!');
      return;
    }

    if(!(props.mapDrawFeatures?.features instanceof Array)) {
      return;
    }

    source.setData(props.mapDrawFeatures || {
      type: 'FeatureCollection',
      features: []
    });
  }, [props.mapDrawFeatures, loaded]);

  React.useEffect(() => {
    if(elementRef?.current?.classList) {
      elementRef.current.classList.add(`select-${props.selectMode || 'default'}`);
    }
    return () => {
      if(elementRef?.current?.classList) {
        elementRef.current.classList.remove(`select-${props.selectMode || 'default'}`);
      }
    }
  }, [props.selectMode]);

  const displayActionTip = props.selectMode == 'move' || props.selectMode == 'target';

  return <>
    <div style={{flex: 1, width: '100%', height: '100%'}} className="map" ref={elementRef as any}>
      <div style={{
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 8,
        width: 'fit-content',
        margin: 'auto',
        backgroundColor: '#3e3f46',
        padding: '4px 8px',
        borderRadius: '4px',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
        display: displayActionTip ? 'flex' : 'none',
        zIndex: 10001
      }}>
        {props?.selectMode === 'move' ? 'Click on map to select destination' : null}
        {props?.selectMode === 'target' ? 'Click anywhere on map to attack.' : null}
        <span
          onClick={props.onCancelAction}
          className={'cancel-action-button'}
          dangerouslySetInnerHTML={{__html:cancelSvg}}></span>
      </div>
    </div>
    {layersModal.root}
  </>
}

const cancelSvg = `
<svg width="24" height="24" viewBox="0 0 682 682" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M559.24 498.398L401.842 341L559.24 183.602L498.398 122.76L341 280.158L183.602 122.76L122.76 183.602L280.158 341L122.76 498.398L183.602 559.24L341 401.842L498.398 559.24L559.24 498.398Z" fill="white"/>
</svg>
`