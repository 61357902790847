export interface AvatarProps {
 team: 'red' | 'white' | 'blue' | 'info';
 color?: string;
 size?: 'default' | '27';
 selected?: boolean;
 onClick?: () => void;
 notification?: boolean;
}

export function Avatar(props: AvatarProps) {
  let iconColor = '#D9D9D9';
  let iconText = 'i';
  let textColor = '#D9D9D9';
  let fontSize = '24px';
  let size = 43;
  let border = 'none';

  if(props.team === 'blue') {
    iconColor = props.color || '#1862D1';
    iconText = 'B';
    textColor = '#D9D9D9';
  } else if(props.team === 'red') {
    iconColor = props.color || '#D11212';
    iconText = 'R';
    textColor = '#D9D9D9';
  } else if(props.team === 'white') {
    iconColor = props.color || '#D9D9D9';
    iconText = 'W';
    textColor = 'black';
  } else if(props.team === 'info') {
    iconColor = '#D9D9D9';
    iconText = 'i';
    textColor = 'black';
  }

  if(props.color)  {
    iconColor = props.color;
    iconText = props.team[0].toUpperCase();
  }

  if(props.size === '27') {
    size = 27;
    fontSize = '16px';
  }

  if(props.selected) {
    border = '2px solid white';
  }

  return <div
    onClick={props.onClick}
      style={{
      position: 'relative',
      width: size,
      height: size,
      backgroundColor: iconColor,
      borderRadius: 4,
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: fontSize,
      fontWeight: 400,
      color: textColor,
      boxSizing: 'border-box',
      border,
      cursor: props.onClick ? 'pointer' : 'initial'
  }}>
    {iconText}
    {props.notification ? <>
      <div style={{position: 'absolute', top: -5, right: -5, width: 10, height: 10, backgroundColor: 'red', borderRadius: 8}}></div>
    </> : null}
  </div>
}
