import React, { CSSProperties, useState } from "react";
import { ModalView } from './ModalView';
import { Actor } from '@wargamer/types';
import { useModal } from '../hooks';
import { useGameSession } from '../hooks';

export function useViewOobModal() {
  const session = useGameSession();
  const modal = useModal();
  const open = () => {
    const stats = buildStats(session.gameState.assets.getAll(), session.playerTeam);
    modal.open(<ViewOobModal
      stats={stats}
      teamId={session.playerTeam}
      onClose={modal.close}
    />);
  }

  return {
    open,
    close: modal.close,
    isOpen: modal.isOpen,
    root: modal.root,
  };
}

function buildStats(actors: Actor[], teamId: string) {
  // const session = useGameSession();
  const stats = {
    'locations': {
      'available': 0,
      'destroyed': 0,
      'total': 0
    },
    'weapons': {
      'available': 0,
      'destroyed': 0,
      'expended': 0,
      'total': 0
    },
    'targetsDestroyed': [] as string[],
    'weaponCounts': [] as WeaponCount[]
  };
  
  actors.filter(a => a.actorType !== 'weapon').forEach(element => {
    if (element.teamId !== teamId) {
      if (element.status === 'available') {
        stats.locations.available = stats.locations.available + 1;
      }
      if (element.status === 'destroyed') {
        stats.locations.destroyed = stats.locations.destroyed + 1;
        stats.targetsDestroyed.push(element.name);
      }
      stats.locations.total = stats.locations.total + 1;
    } 
  });
  
  actors.filter(a => a.actorType === 'weapon').forEach(element => {
    if (element.teamId === teamId) {
      if (element.status === 'available') {
        stats.weapons.available = stats.weapons.available + 1;
      }
      if (element.status === 'destroyed') {
        stats.weapons.destroyed = stats.weapons.destroyed + 1;
      }
      if (element.status === 'expended') {
        stats.weapons.expended = stats.weapons.expended + 1;
      }
      stats.weapons.total = stats.weapons.total + 1;

      var index = stats.weaponCounts.map(function(e) { return e.name; }).indexOf(element.name);
      if (index === -1) {
        const newWeapon: WeaponCount = {
          'name': element.name,
          'count': 1,
          'range': element.range
        };
        stats.weaponCounts.push(newWeapon);
      } else {
        stats.weaponCounts[index].count = stats.weaponCounts[index].count + 1;
      }
    } 
  });
  return stats;
};

interface ViewOobModalProps {
  stats: any;
  teamId: string;
  onClose: () => void;
}
interface WeaponCount {
  name: string;
  count: number;
  range: number;
}

const buttonStyle: CSSProperties = {
  fontWeight: 'bold',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  width: 117,
  height: 40,
  borderRadius: .5,
  backgroundColor: '#D9D9D9'
};

function ViewOobModal(props: ViewOobModalProps) {
  console.log(props.stats);
  return <ModalView title={<>Order of Battle Details for {props.teamId}</>}>
    
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', textAlign: 'center', paddingTop: '16px'}}>
      <div style={{border: '1px solid #ccc', padding: '18px 10px 8px 10px'}}>
        <b style={{fontWeight: 'bold', fontSize: '22px', lineHeight: '12px'}}>{props.stats?.weapons.available}</b><br/>
        Available Missiles
      </div>
      <div style={{border: '1px solid #ccc', padding: '18px 10px 8px 10px'}}>
        <b style={{fontWeight: 'bold', fontSize: '22px', lineHeight: '12px'}}>{props.stats?.weapons.expended}</b><br/>
        Expended Missiles
      </div>
      <div style={{border: '1px solid #ccc', padding: '18px 10px 8px 10px'}}>
        <b style={{fontWeight: 'bold', fontSize: '22px', lineHeight: '12px'}}>{props.stats?.locations.destroyed}</b><br/>
        Targets Destroyed
      </div>
      <div style={{border: '1px solid #ccc', padding: '18px 10px 8px 10px'}}>
        <b style={{fontWeight: 'bold', fontSize: '22px', lineHeight: '12px'}}>{props.stats?.weapons.destroyed}</b><br/>
        Munitions Lost
      </div>
    </div>
    <div>
      <div style={{fontWeight: 'bold', fontSize: '16px', paddingTop: '10px'}}>Available Weapon Types</div>
      <ul style={{maxHeight: '100px', overflowY: 'scroll'}}>
        {props?.stats.weaponCounts.map((weapon: WeaponCount) => {
          return <li>{weapon.name}, Units available: {weapon.count}, Range: {weapon.range}km</li>
        })}
        {props?.stats.weaponCounts.length === 0 ? 'No weapons available for ' + props.teamId : ''}
      </ul>
    </div>
    <div>
      <div style={{fontWeight: 'bold', fontSize: '16px'}}>Targets Destroyed</div>
      <ul style={{maxHeight: '100px', overflowY: 'scroll'}}>
        {props?.stats.targetsDestroyed.map((target: string) => {
          return <li>{target}</li>;
        })}
        {props?.stats.targetsDestroyed.length === 0 ? 'No targets destroyed by ' + props.teamId : ''}
      </ul>
    </div>
    
    <div style={{display: 'flex', alignItems: 'center'}}>
      <button style={buttonStyle} onClick={props.onClose}>Close</button>
    </div>
  </ModalView>;
}