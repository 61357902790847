import axios from 'axios';
import { API_HOST } from '../config';
import { StartGameOptions, ReloadGameOptions } from '@wargamer/types';

export async function startGameAction(options: StartGameOptions , gameId: string, token: string) {
  await axios.post(`${API_HOST}/game-play/${gameId}/clock/start`, options, {
    headers: {
      token
    }
  });
}

export async function sendClockAction(action: 'play' | 'pause' | 'stop' | 'restart', gameId: string, token: string) {
  await axios.get(`${API_HOST}/game-play/${gameId}/clock/${action}`, {
    headers: {
      token
    }
  });
}

export async function reloadGameData( options: ReloadGameOptions, token: string) {
  await axios.post(`${API_HOST}/reload-game-data`, options, {
    headers: {
      token
    }
  });
  alert("Reload complete. Reloading Browser.");
  window.location.reload();
}