import React from 'react';
import { GameScreen, XChatMessage, useAlertModal } from './components';
import { sendChat, sendClockAction, runAction, startGameAction, setMapFeatures, reloadGameData } from './api';
import { useAsync, useGameSession, useCollectionValues, useValue, useGameState, useActorRenderer } from './hooks';
import { Action, Actor, ActorMapState, ChatMessage, StartGameOptions } from '@wargamer/types';
import { FeatureCollection } from 'geojson';

export interface PlayerViewProps {}

export function PlayerView(props: PlayerViewProps) {
  const session = useGameSession();
  const alertModal = useAlertModal();
  const chat = useAsync((msg: ChatMessage) => sendChat(msg, session.gameId, session.playerToken));
  const clockApi = useAsync((action: 'play' | 'pause' | 'stop' | 'restart') => sendClockAction(action, session.gameId, session.playerToken));
  const mapApi = useAsync((features: FeatureCollection) => setMapFeatures(features, session.gameId, session.playerToken));
  const clock = useValue(session.gameState.clock);
  const fireAction = useAsync((action: Action) =>
    runAction(session.gameId, action, session.playerToken), {
      onError: (err) => {
        console.log(err.response?.data?.message);
        if(err.response?.data?.message) {
          alertModal.open({
            title: "Notice",
            message: err.response?.data?.message
          });
        }
      }
    });
  const startGame = useAsync((options: StartGameOptions) => startGameAction(options, session.gameId, session.playerToken));
  const messages = useCollectionValues(session.gameState.messages);
  const updates = useCollectionValues(session.gameState.updates);
  const teams = useCollectionValues(session.gameState.teams);
  const mapFeatures = useValue(session.gameState.mapFeatures);
  const actorRenderer = useActorRenderer();

  const renderMapIcon = React.useCallback((actor: Actor, state: ActorMapState) => {
    return actorRenderer.renderMapIcon(actor, state);
  }, [actorRenderer]);

  const renderActorStatus = React.useCallback((actor: Actor) => {
    return actorRenderer.renderStatus(actor);
  }, [actorRenderer]);

  const renderActorIcon = React.useCallback((actor: Actor) => {
    return actorRenderer.renderSvgIcon(actor);
  }, [actorRenderer]);

  const xMessages = messages.map((m) => {
    const xMsg: XChatMessage = {
      ...m,
      fromMe: m.from === session.playerId
    };

    return xMsg;
  });

  const actors = useCollectionValues(session.gameState.assets);

  const onAction = (action: Action) => {
    if(action.action === 'move') {
      fireAction.call(action);
    } else if(action.action === 'attack') {
      if(action.targetedActorId) {
        action.point = null;
      }
      fireAction.call(action);
    } else if(action.action === 'stop') {
      fireAction.call(action);
    } else if(action.action === 'shutdown') {
      fireAction.call(action);
    }

  }

  return <>
    <GameScreen
      key={session.playerId}
      state={session.gameState}
      onStartGame={options => startGame.call(options)}
      teamId={session.playerTeam}
      time={clock.time || 0}
      realTime={clock.realTime || 0}
      status={clock.state}
      actors={actors}
      updates={updates}
      teams={teams}
      playerId={session.playerId as any}
      messages={[
        ...xMessages
      ]}

      onSendMessage={(message, to) => chat.call({
        id: 0,
        from: session.playerId,
        to,
        message,
        ts: 0,
        time: 0
      })}

      displayFireControl={true}
      onFireWeapon={(w, l, d) => null}
      onAction={onAction}
      displayGameControls={session.playerId === 'white'}
      onPause={() => {clockApi.call('pause'); alert('Scenario paused. Click Play to unpause the game.')}}
      onRestart={() => clockApi.call('restart')}
      onStop={() => clockApi.call('stop')}
      onPlay={() => clockApi.call('play')}

      mapDrawFeatures={mapFeatures}
      onDrawChanges={mapApi.call}
      renderMapIcon={renderMapIcon}
      renderActorStatus={renderActorStatus}
      renderActorIcon={renderActorIcon}
      onReloadGameData={reloadGameData as any}
    />
    {alertModal.root}
  </>
}