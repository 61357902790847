export class MapboxLayerControlButton {
  constructor(private onClick: () => void) { }
  
  onAdd(map) {
    const button = document.createElement("div");
    button.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
    button.innerHTML = `<button style="display: flex; align-items:center; justify-content: center">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000000" viewBox="0 0 256 256"><path d="M234.36,170A12,12,0,0,1,230,186.37l-96,56a12,12,0,0,1-12.1,0l-96-56a12,12,0,0,1,12.09-20.74l90,52.48L218,165.63A12,12,0,0,1,234.36,170ZM218,117.63,128,170.11,38.05,117.63A12,12,0,0,0,26,138.37l96,56a12,12,0,0,0,12.1,0l96-56A12,12,0,0,0,218,117.63ZM20,80a12,12,0,0,1,6-10.37l96-56a12.06,12.06,0,0,1,12.1,0l96,56a12,12,0,0,1,0,20.74l-96,56a12,12,0,0,1-12.1,0l-96-56A12,12,0,0,1,20,80Zm35.82,0L128,122.11,200.18,80,128,37.89Z"></path></svg>
    </button>`;
    button.addEventListener("click", this.onClick);

    return button;
  }

  onRemove() {}
}