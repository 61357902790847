import { Action, Actor, GameClock, IActorMainController, IGameMainController} from '@wargamer/types';
import { LocationActor } from './types';

export class LocationMain implements IActorMainController<LocationActor> {
  readonly name = 'location';

  constructor(private game: IGameMainController) { }

  validate(asset: LocationActor) {

  };

  tick(asset: LocationActor, clock: GameClock) {

  }

  attack(weapon: LocationActor, action: Action): void {
    
  }

  move(asset: LocationActor, action: Action): void {

  }

  stop(asset: LocationActor): void {

  }

  shutdown(asset: LocationActor): void {
    
  }

}