import React, { CSSProperties } from "react";
import { ModalView } from './ModalView';
import { Action } from '@wargamer/types';
import { useActor, useActorRenderer, useModal } from '../hooks';

export interface ConfirmAttackModalOptions {
  attack: Action;
  onAttack: (action: Action) => void;
}

export function useConfirmAttack() {
  const modal = useModal();
  const open = (options: ConfirmAttackModalOptions) => {
    modal.open(<ConfirmAttack
      options={options}
      onAttack={(attack) => {
        modal.close();
        if(attack) {
          options.onAttack(attack);
        }
      }}
    />);
  }

  return {
    open,
    close: modal.close,
    isOpen: modal.isOpen,
    root: modal.root,
  };
}

interface ConfirmAttackProps {
  options: ConfirmAttackModalOptions;
  onAttack: (action: Action) => void;
}

const buttonStyle: CSSProperties = {
  fontWeight: 'bold',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  width: 117,
  height: 40,
  borderRadius: .5,
  backgroundColor: '#D9D9D9'
};

function ConfirmAttack(props: ConfirmAttackProps) {
  const [action, setAction] = React.useState(props.options.attack);
  const selectedWeaponActor = useActor(action?.actorId);
  const actorRenderer = useActorRenderer();

  return <ModalView title="Confirm Attack">
    <div style={{ display: 'flex', flexDirection: 'column', gap: 24, height: '100%' }}>

      <div style={{border: '1px solid #ccc', padding: '10px', borderRadius: '4px', flexGrow: 1}}>
        {/* 
          Looking for the detonation selector inputs?
          Each actor can render it's own form. See packages/actors/src/Missile/MissileRenderer.tsx and the `renderAttackForm` method.
         */}
        {actorRenderer.renderAttackForm(selectedWeaponActor, action, setAction)}
      </div>

      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row'}}>
        <button style={buttonStyle} onClick={() => props.onAttack(null)}>Cancel</button>
        <button style={buttonStyle} onClick={() => props.onAttack(action)}>Attack</button>
      </div>

    </div>
  </ModalView>;
}