import { Actor } from "@wargamer/types";

export function hasWeaponsAvailable(actorId: string, actors: Actor[]) {
  const children = actors.filter(a => a.parentActor === actorId);

  return children.find(c => {
    if(c.actorType == 'weapon' && c.status === 'available') {
      return true;
    } else {
      return false;
    }
  }) ? true : false;
}