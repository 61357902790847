import React from 'react';
import { Layout } from './Layout';
import { Panel } from './Panel';
import { Header } from './Header';
import { GameUpdate, GameUpdateList } from './GameUpdateList';
import { XChatMessage } from './Chat';
import { ChatView } from './ChatViewSwitch';
import { Clock } from './Clock';
import { Map, MapClickEvent, MapProps } from './Map';
import { Actor, StartGameOptions, Team, IGameState, ActorMapState, ActorMapItem, Action, ReloadGameOptions } from '@wargamer/types';
import { useModal } from '../hooks';
import { StartGameModal } from './StartGameModal';
import { useViewWeaponModal } from './ViewWeaponModal';
import { useViewLocationModal } from './ViewLocationModal';
import { FeatureCollection } from 'geojson';
import { useViewOobModal } from './ViewOobModal';
import { useActionPopover } from './ActionPopover';
import { ActorControlMenu } from './ActorControlMenu';
import { Actions } from './ActionButton';
import { useFindActorModal } from './FindActorModal';
import { FindActorButton } from './FindActorButton';
import { useConfirmAttack } from './ConfirmAttackModal';

export interface GameScreenProps {
  state: IGameState;
  time: number;
  realTime: number;
  status: 'new' | 'running' | 'paused' | 'stopped';
  updates: GameUpdate[];
  actors: Actor[];
  teams: Team[];
  teamId: string;
  playerId: string;

  onStartGame: (options: StartGameOptions) => void;
  messages: XChatMessage[];
  onSendMessage: (msg: string, to: string) => void;

  displayFireControl: boolean;
  onFireWeapon: (weaponId: string, locationId: string, detonationType: 'ground' | 'air') => void;
  onAction: (action: Action) => void;

  displayGameControls: boolean;
  onPause: () => void;
  onRestart: () => void;
  onStop: () => void;
  onPlay: () => void;

  mapDrawFeatures: FeatureCollection;
  onDrawChanges: (features: FeatureCollection) => void;
  renderMapIcon: (actor: Actor, state: ActorMapState) => Promise<ActorMapItem>;
  renderActorStatus: (actor: Actor) => JSX.Element;
  renderActorIcon: (actor: Actor) => JSX.Element;
  onReloadGameData: (options: ReloadGameOptions) => void;
}

export function GameScreen(props: GameScreenProps) {
  const launchGameModal = useModal();
  const viewWeaponModal = useViewWeaponModal();
  const viewLocationModal = useViewLocationModal();
  const viewOobModal = useViewOobModal();
  const actionPopover = useActionPopover();
  const findActorModal = useFindActorModal();
  const confirmAttackModal = useConfirmAttack();

  React.useEffect(() => {

    if (!props.teamId) {
      return;
    }

    if (props.status === 'new') {
      launchGameModal.open(<StartGameModal teamId={props.teamId} onStart={props.onStartGame} onReloadGameData={props.onReloadGameData}/>);
    } else {
      launchGameModal.close();
    }
  }, [props.status, props.teamId]);

  const [selectedActor, setSelectedActor] = React.useState('');
  const [targetedActor, setTargetedActor] = React.useState('');
  const [locateActor, setLocateActor] = React.useState('');
  const [selectMode, setSelectMode] = React.useState<MapProps['selectMode']>('default');

  const stateRef = React.useRef({
    selectedActor,
    targetedActor,
    selectMode: selectMode,
    onAction: props.onAction,
    actors: props.actors,
    teams: props.teams
  });

  stateRef.current.selectedActor = selectedActor;
  stateRef.current.targetedActor = targetedActor;
  stateRef.current.onAction = props.onAction;
  stateRef.current.selectMode = selectMode;
  stateRef.current.actors = props.actors;
  stateRef.current.teams = props.teams;
  const state = stateRef.current;

  const confirmAttack = (action: Action) => {
    confirmAttackModal.open({
      attack: action,
      onAttack: state.onAction
    });
  }

  const onMapItemClick = (event: MapClickEvent) => {
    const item = event.actors[0];

    if(event?.type === 'primary' && item?.status === 'expended') {
      viewWeaponModal.open(item as any);
      return;
    }

    if(state.selectMode === 'move') {
      state.onAction({
        action: 'move',
        actorId: state.selectedActor,
        targetedActorId: state.targetedActor,
        point: event.point,
      });

      setSelectMode('default');
      return;
    }

    if(state.selectMode === 'target') {
      confirmAttack({
        action: 'attack',
        actorId: state.selectedActor,
        targetedActorId: event.actors?.[0]?.id,
        point: event.point,
      });

      setSelectMode('default');
      return;
    }

    if(event.type === 'primary' && item?.id) {
      setSelectedActor(item.id);
    } else if(event.type === 'primary') {
      setSelectedActor('');
      setTargetedActor('');
    } else if(event.type === 'context' && selectedActor) {
      const focusedActor = props.actors.find(a => a.id === selectedActor);
      const target = props.actors.find(a => a.id === item?.id);
      setTargetedActor(item?.id);
      actionPopover.open({
        position: event.cursor,
        focusedActor,
        target,
        teamId: props.teamId,
        onAttack: () => {
          actionPopover.close();
        },
        onMove: () => {
          actionPopover.close();
        }
      });
    }
  }

  const onAction = React.useCallback((action: Actions) => {
    if(action === 'move') {
      return setSelectMode('move');
    } else if(action === 'attack') {
      return setSelectMode('target');
    } else if(action === 'find-attack') {
      return findActorModal.open({
        actionLabel: 'Attack',
        actors: state.actors,
        onSelection: (a) => {
          if(a) {
            confirmAttack({
              action: 'attack',
              actorId: state.selectedActor,
              targetedActorId: a.id,
              point: null
            });
          }
        },
        subTitle: 'test',
        teams: state.teams,
        title: 'Search to Attack'
      });
    } else if(action === 'deselect') {
      setTargetedActor('');
      setSelectedActor('');
    } else if(action === 'shutdown') {
      return state.onAction({
        action: 'shutdown',
        actorId: state.selectedActor
      });
    } else if(action === 'stop') {
      return state.onAction({
        action: 'stop',
        actorId: state.selectedActor
      });
    } else if(action === 'locate') {
      return setLocateActor(state.selectedActor + ':' + Math.random());
    }

    state.onAction({
      action: action as any,
      actorId: state.selectedActor,
      targetedActorId: state.targetedActor
    });
  },[]);

  return (
      <Layout
        bottom={<div style={{ flexDirection: 'row' }}>
          <Panel
            title={<>
              Command and Control
              <div style={{flexGrow:1}}></div>
              <FindActorButton
                actors={props.actors}
                teams={props.teams}
                onActor={(a) => {
                  if(a) setSelectedActor(a.id);
                }}
                renderActorIcon={props.renderActorIcon}
              />
            </>}
            style={{ height: 338, padding: 0, border: '1px solid black'}}
          >
            <ActorControlMenu
             actors={props.actors}
             selectedActor={selectedActor}
             targetedActor={targetedActor}
             onSelectActor={setSelectedActor}
             onAction={onAction}
             renderActorStatus={props.renderActorStatus}
            />
          </Panel>
        
          <Panel
            title="Sit Rep"
            style={{ height: 338, padding: 0, border: '1px solid black', maxWidth: '275px' }}
          >
            <GameUpdateList
              updates={props.updates.filter(u => u.type === 'sit-rep')}
              onClick={(u) => u.actorId ? setSelectedActor(u.actorId) : null}
            />
          </Panel>

          <Panel
            title={<>
              AAR
              <a style={{border: '1px solid #ccc', padding: '2px 4px', borderRadius: '3px', cursor: 'pointer'}} onClick={() => viewOobModal.open()}>OOB Report</a>
            </>}
            style={{ height: 338, padding: 0, border: '1px solid black', maxWidth: '275px'}}
          >
            <GameUpdateList
              updates={props.updates.filter(u => u.type === 'aar')}
              onClick={(u) => u.actorId ? setSelectedActor(u.actorId) : null}
            />
          </Panel>

        </div>}
        header={<Header />}
        main={<>

          <div style={{
            position: 'absolute',
            top: 42,
            left: 0,
            boxShadow: '0px 4px 4px rgba(0,0,0,.25)',
            zIndex: 1000,
          }}>
            <Clock
              status={props.status}
              time={props.time}
              realTime={props.realTime}
              displayGameControls={props.displayGameControls}
              onPause={props.onPause}
              onRestart={props.onRestart}
              onStop={props.onStop}
              onPlay={props.onPlay}
            />
          </div>
          <Map
            selectedActor={selectedActor}
            targetedActor={targetedActor}
            onClick={onMapItemClick}
            actors={props.actors}
            allowDrawing={props.teamId === 'white'}
            onDrawChanges={props.onDrawChanges}
            mapDrawFeatures={props.mapDrawFeatures}
            renderMapIcon={props.renderMapIcon}
            selectMode={selectMode}
            locateActor={locateActor}
            onCancelAction={() => {
              setSelectMode('default');
            }}
          />
          {launchGameModal.root}
          {viewWeaponModal.root}
          {viewLocationModal.root}
          {viewOobModal.root}
          {actionPopover.root}
          {findActorModal.root}
          {confirmAttackModal.root}
        </>}
        right={<>
          <ChatView
            chats={props.teams}
            chatPlayerId={props.teamId}
            messages={props.messages}
            onSend={props.onSendMessage}
          />
        </>}
      />
  );
}
