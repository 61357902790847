import { Actor } from "@wargamer/types";
import { Virtuoso } from 'react-virtuoso';
import { ActorAvatar } from './ActorAvatar';

export interface ActorListProps {
  actors: Actor[];
  onClick: (actor: Actor) => void;
  onAction?: (actor: Actor) => void;
  actionLabel?: string;
}

export function ActorList(props: ActorListProps) {

  if(props.actors.length < 10) {
    const fragments = props.actors.map((a) => <Child
      key={a.id}
      actor={a}
      onClick={props.onClick}
      onAction={props.onAction}
      actionLabel={props.actionLabel}
    />);

    return <div style={{overflow: 'auto'}}>
      {fragments}
    </div>
  }

  return <Virtuoso
    style={{ height: "300px" }}
    itemSize={() => 54}
    totalCount={props.actors.length}
    data={props.actors}
    overscan={10}
    computeItemKey={index => props.actors[index].id}
    itemContent={(index) => <Child
      actor={props.actors[index]}
      onClick={props.onClick}
      onAction={props.onAction}
      actionLabel={props.actionLabel}
    />}
  />
}

interface ChildProps {
  actor: Actor;
  onClick: (actor: Actor) => void;
  onAction?: (actor: Actor) => void;
  actionLabel?: string;
}

function Child(props: ChildProps) {
  return <div
    style={{display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center', flexShrink: 0, minHeight: 54, maxHeight: 54, marginTop: 4, marginBottom: 4}}
    onClick={() => props.onClick(props.actor)}
  >
    <ActorAvatar actorId={props.actor.id}/>
    <div>
      {props.actor.name}
    </div>
  </div>;
}