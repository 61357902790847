import { IActorRendererController, IGameState, ActorMapItem, ActorMapState, Actor} from '@wargamer/types';
import { BattleshipActor } from './types';
import { memCreateIcon, createIconKey, IconOptions } from '../shared/icon';
import { getMoveCurrent, isActorMoving } from '../shared/move';
import { StatusBadge } from '../shared/StatusBadge';
import { SvgIcon } from '../shared/SvgIcon';
import { svgs } from '../shared/svgs';

import { Feature } from 'geojson';

export class BattleshipRenderer implements IActorRendererController<BattleshipActor> {
  readonly name = 'battleship';

  constructor(private state: IGameState) { }

  renderStatus(actor: BattleshipActor) {
    if(isActorMoving(actor)) {
      return <StatusBadge type='info'>En Route to Destination</StatusBadge>;
    } else if(actor.status === 'destroyed') {
      return <StatusBadge type='warning'>Destroyed</StatusBadge>;
    } else  {
      return <StatusBadge type='info'>Ready</StatusBadge>;
    }
  }

  renderSvgIcon(asset: BattleshipActor) {
    const svg = (svgs[asset.icon] || svgIcon).replace('{COLOR}', 'white');
    return <SvgIcon>{svg}</SvgIcon>;
  }

  renderMapIcon(asset: BattleshipActor, state: ActorMapState) {
    const team = this.state.teams.get(asset.teamId);

    const iconOptions: IconOptions = {
      backgroundColor: 'rgba(0,0,0,0)',
      color: team?.primaryColor || 'white',
      icon: (svgs[asset.icon] || svgIcon),
      selected: state.selected,
      targeted: state.targeted,
      frame: true,
      opacity: asset.status === 'destroyed' ? .4 : 1,
    };

    if(this.state.iconType === 'mil' && asset.milSymbol) {
      iconOptions.icon = '';
      iconOptions.milSymbol = {
        symbol: asset.milSymbol,
        options: { }
      }
    }

    const icon = memCreateIcon(iconOptions);
    const iconId = createIconKey(iconOptions);

    const time = this.state.getHdTime();
    const current = getMoveCurrent(asset, time);

    let heading = current.heading;

    if(asset.status !== 'en-route') {
      heading = 0;
    } else {

      // flip the ship so that it is never upside down
      if(current.heading < 180) {
        heading = current.heading - 90;
      } else if(current.heading >= 180) {
        heading = current.heading - 260;
      }
    }

    const feature: Feature = {
      geometry: {
        coordinates: current.point,
        type: 'Point',
      },
      id: asset.id,
      properties: {
        id: asset.id,
        icon: iconId,
        heading: heading
      },
      type: 'Feature'
    }

    const item: ActorMapItem = {
      icons: {
        [iconId]: icon
      },
      actorFeatures: [feature]
    }
 
    return item;
  }
}

const svgIcon = `
<svg viewBox="0 0 33 12" fill="{COLOR}" xmlns="http://www.w3.org/2000/svg">
<path d="M2.28244 11.5402L0.729492 9.97028L5.63963 9.97584L5.63751 9.35711H9.8162L9.81276 8.5048H13.9859L13.9716 7.59236H14.4366V6.52851H16.4038L16.6432 2.21965H15.7957L15.7999 1.99618H16.643L16.6864 0.491211H16.824L16.8529 1.97629H17.6421L17.6495 2.21965H16.8704L17.0987 6.52823H18.2985L18.2842 4.86664C18.0841 4.77306 17.9454 4.57026 17.9454 4.33512C17.9454 4.01118 18.2081 3.74873 18.5318 3.74873H18.93C19.2539 3.74873 19.5164 4.01118 19.5164 4.33512C19.5164 4.56231 19.3867 4.75981 19.1975 4.85657L19.2049 6.46333H20.1791V7.91841H23.3311V8.25482L31.7497 8.25906L32.7295 8.201L30.2148 11.5404"/>
<path d="M2.28223 9.69914H4.52997V8.13561C4.52997 8.13561 2.3148 8.07066 2.28223 9.69914Z"/>
<path d="M9.23895 9.07664H6.99121V7.5131C6.99148 7.51284 9.20665 7.44789 9.23895 9.07664Z"/>
<path d="M27.4431 8.07029H25.1953V6.50676C25.1953 6.50649 27.4105 6.44154 27.4431 8.07029Z"/>
<path d="M2.72595 8.41025L1.37266 7.91797L1.29102 8.14251L2.50226 8.58336C2.502 8.58336 2.60745 8.47838 2.72595 8.41025Z"/>
<path d="M8.72563 7.77308L10.0795 7.28027L10.1611 7.50481L8.94986 7.94566C8.94959 7.94566 8.84412 7.84095 8.72563 7.77308Z"/>
<path d="M26.9102 6.75307L28.264 6.26025L28.3457 6.48479L27.1344 6.92565C27.1344 6.92618 27.0287 6.8212 26.9102 6.75307Z"/>
</svg>
`