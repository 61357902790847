import { GameState } from "./GameState";
import { ActorMainCollection } from './ActorMainCollection';
import { ChatMessage, GameUpdate, StartGameOptions, IGameMainController, Action } from '@wargamer/types';

export class GameMain implements IGameMainController {

  constructor(
    public state: GameState,
    private h: ActorMainCollection,
  ) {
    h.init(this);
  }

  validate() {
    const actors = this.state.assets.getAll();
    actors.forEach((a) => this.h.validate(a));
  }

  // Chat Controls
  sendMessage(msg: ChatMessage) {
    msg.id = Date.now();
    msg.ts = msg.id;
    msg.time = this.state.clock.get().time;
    this.state.messages.add(msg);
  }

  reset() {
    this.state.reset();
  }

  start(options: StartGameOptions) {
    const scenarioTime = options.scenarioTime || 1;
    const inGameTime = options.inGameTime || (scenarioTime / 24);
    const inGameTimeHr = inGameTime * 24;

    const timeDilation = inGameTimeHr / scenarioTime;

    this.state.clock.update({
      inGameTime,
      scenarioTime,
      timeDilation,
      state: 'running'
    });

    this.startGameTick();
  }

  // Game Clock Controls
  play() {
    this.startGameTick();
    this.state.clock.update({state: 'running'});
  }

  pause() {
    this.stopGameTick();
    this.state.clock.update({state: 'paused'});
  }

  stop() {
    this.stopGameTick();
    this.state.clock.update({state: 'stopped'});
  }

  stopAction(action: Action) {
    const actor = this.state.assets.get(action.actorId);

    if(!actor) {
      throw new Error('actor not found');
    }

    if(this.state.clock.get().state !== 'running') {
      throw new Error('clock not running');
    }

    this.h.stop(actor);
  }

  shutdown(action: Action) {
    const actor = this.state.assets.get(action.actorId);

    if(!actor) {
      throw new Error('actor not found');
    }

    if(this.state.clock.get().state !== 'running') {
      throw new Error('clock not running');
    }

    this.h.shutdown(actor);
  }

  attack(action: Action): void {
    const actor = this.state.assets.get(action.actorId);

    if(!actor) {
      throw new Error('actor not found');
    }

    if(this.state.clock.get().state !== 'running') {
      throw new Error('clock not running');
    }

    this.h.attack(actor, action);
  }

  move(action: Action) {

    const actor = this.state.assets.get(action.actorId);

    if(!actor) {
      throw new Error('actor not found');
    }

    if(this.state.clock.get().state !== 'running') {
      throw new Error('clock not running');
    }

    this.h.move(actor, action);
  }

  addGameUpdate(update: Partial<GameUpdate>) {
    const ts = Date.now();
    this.state.updates.add({
      teamId: 'white',
      message: '',
      type: 'aar',
      ...update,
      id: ts.toString(),
      time: this.state.clock.get().time,
      timestamp: ts,
    })
  } 

  private runTick() {
    const clock = this.state.clock.get();
    this.state.assets.getAll()
    .forEach(asset => this.h.tick(asset, clock))
  }

  private tickerInterval: any = null;
  private startGameTick() {
    if(this.tickerInterval) {
      return;
    }

    this.tickerInterval = setInterval(() => {
      const currentTime = (this.state.clock.get().realTime || 0) + 1;
      const clockTime = currentTime * (this.state.clock.get().timeDilation || 1)
      this.state.clock.update({
        realTime: currentTime,
        time: clockTime
      });
      this.runTick();
    }, 1000);
  }

  private stopGameTick() {
    clearInterval(this.tickerInterval);
    this.tickerInterval = null;
  }
}