import React from "react";

export function useCssVariables(vars: ({[key: string]: string})) {
  const state = React.useRef(vars);
  const update = React.useCallback((vars: ({[key: string]: string}), element: HTMLDivElement) => {
    if(!element) {
      state.current = {};
      return;
    }

    Object.keys(vars).forEach(key => {
      if(state.current[key] !== vars[key]) {
        ref.current.style.setProperty(key, vars[key]);
      }
    });

    state.current = vars;
  }, []);

  const ref = React.useRef<HTMLDivElement>();

  update(vars, ref.current);

  return (element: HTMLDivElement) => {
    ref.current = element;
    update(vars, element);
  };
}

