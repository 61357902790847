import { Action, Actor, IGameState} from '@wargamer/types';
import { MissileActor } from './types';
import { getMoveStartAndDestination } from '../shared/move';
import { getActorsInBlastZone } from './getActorsInBlastZone';
import { calculateCasualties } from './calculateCasualties';
import React from 'react';

export interface AttackFormProps {
  actor: MissileActor;
  action: Action;
  state: IGameState;
  onChange: (action: Action) => void;
}

export function AttackForm(props: AttackFormProps) {

  const destroyedActors = React.useMemo(() => {
    const data =  getActors(props.actor, props.action, props.state);
    return data;
  }, [props.actor, props.action, props.state]);

  return <>
  <div style={{flexDirection: 'column'}}>
    <span style={{color: 'red', fontWeight: 'bold'}}>
      Weapon and target have been selected. Click Fire Weapon to fire.
    </span>

    {!props.actor.blastRadius ? <>
      <label onClick={() => props.onChange({...props.action, detonationType: 'air'})}>
        <input type="radio" checked={props.action.detonationType === 'air'} onChange={() => null} style={{position: 'relative', top: '5px'}}/>
          Air Burst
      </label>
      <label onClick={() => props.onChange({...props.action, detonationType: 'ground'})}>
        <input type="radio" checked={props.action.detonationType === 'ground'} onChange={() => null} style={{position: 'relative', top: '5px'}}/>
          Ground Burst
      </label>
    </> : <>

    </>}

    <hr/>
    <div>
      Missile Realizability: {(props.actor.reliability || 1) * 100}% 
    </div>
    <hr/>
    <div>
      {destroyedActors.items.length > 0 ? <>
        <div>Fatalities: {destroyedActors.fatalities.toLocaleString()}</div>
        <div>Injuries: {destroyedActors.injuries.toLocaleString()}</div>
        <div>Casualties: {destroyedActors.casualties.toLocaleString()}</div>
      </> : null}
      {props.actor.likelyKills ? <>
        <div>{props.actor.likelyKills}</div>
      </> : <></>}
    </div>
  </div>
</>
}

function getActors(actor: MissileActor, action: Action, state: IGameState) {
  const {start, dest} = getMoveStartAndDestination(actor, action, state);
  return calculateCasualties(actor, dest, state, action.detonationType);
}