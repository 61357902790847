import { Action, Actor, GameClock, IActorMainController, IGameMainController} from '@wargamer/types';
import { SiloActor } from './types';

export class SiloMain implements IActorMainController<SiloActor> {
  readonly name = 'silo';

  constructor(private game: IGameMainController) { }

  validate(asset: SiloActor) {
    asset.actorType = 'attack-vehicle';
    return true;
  };

  tick(asset: SiloActor, clock: GameClock) {

  }

  attack(weapon: SiloActor, action: Action): void {
    
  }

  move(asset: SiloActor, action: Action): void {

  }

  stop(asset: SiloActor): void {

  }

  shutdown(asset: SiloActor): void {
    
  }

}