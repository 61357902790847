import { Collection } from './Collection';
import { Single } from './Single';
import { Team, GameClock, DataEvent, ChatMessage, GameUpdate, IGameState, Actor } from '@wargamer/types';
import { FeatureCollection } from 'geojson';

export class GameState implements IGameState {

  private resetData: {
    teams: Team[],
    assets: Actor[]
  } = {
    assets: [],
    teams: [],
  };

  readonly clock = new Single<GameClock>();
  readonly teams = new Collection<Team>(t => t.id);
  readonly messages = new Collection<ChatMessage>(m => m.id);
  readonly updates = new Collection<GameUpdate>(m => m.id);
  readonly mapFeatures = new Single<FeatureCollection>();
  readonly assets = new Collection<Actor>(m => m.id);
  readonly log = new EventLog(this);
  iconType: 'civ' | 'mil' = 'civ';

  private clockTs: number = 0;

  constructor() {
    this.clock.on(({item}) => {
      this.clockTs = Date.now() / 1000;
    });
  }

  getHdTime() {
    const clock = this.clock.get();
    if(!clock) {
      return 0;
    }

    if(clock.state !== 'running') {
      return clock.time;
    }

    const now = Date.now() / 1000;
    const timeSince = (now - this.clockTs) * clock.timeDilation;

    return clock.time + timeSince;
  }

  // Chat Controls
  sendMessage(msg: ChatMessage) {
    msg.id = Date.now();
    this.messages.add(msg);
  }

  reset() {
    this.log.reset();
    this.teams.reset();

    this.teams.add(...this.resetData.teams);
    this.assets.add(...this.resetData.assets);

    this.clock.update({
      state: 'new',
      time: 0,
      realTime: 0
    });
  }

  setResetData(data: GameState['resetData']) {
    this.resetData = {
      assets: data.assets.map(a => ({...a})),
      teams: data.teams.map(a => ({...a})),
    }
  }

  load() {
    this.reset();

    this.teams.add(
      {color: 'orange', id: 'orange', name: 'orange', username: 'orange', password: 'orange', primaryColor: 'orange',secondaryColor: 'orange', role: 'moderator'},
      {color: 'white', id: 'white', name: 'white', username: 'white', password: 'white', primaryColor: 'white',secondaryColor: 'white', role: 'moderator'},
      {color: 'red', id: 'red', name: 'red', username: 'red', password: 'red', primaryColor: 'red',secondaryColor: 'red', role: 'moderator'},
      {color: 'blue', id: 'blue', name: 'blue', username: 'blue', password: 'blue', primaryColor: 'blue',secondaryColor: 'blue', role: 'moderator'},
    );

    // const speed = 10000 * 24;

    // this.weapons.add(
    //   {id: 'B01', teamId: 'blue', name: 'YJ18 Ballistic Missile', range: 500, warhead: '', point: [0, 40.1], status: 'available', speed: speed, kilotons: 800, locationId: ''},
    //   {id: 'B02', teamId: 'blue', name: 'YJ18 Ballistic Missile', range: 500, warhead: '', point: [0, 40.2], status: 'available', speed: speed, kilotons: 800, locationId: ''},

    //   {id: 'R01', teamId: 'red', name: 'YJ18 Ballistic Missile', range: 500, warhead: '', point: [-75, 40.1], status: 'available', speed: speed, kilotons: 800, locationId: ''},
    //   {id: 'R02', teamId: 'red', name: 'YJ18 Ballistic Missile', range: 500, warhead: '', point: [-75, 40.2], status: 'available', speed: speed, kilotons: 800, locationId: ''},
    // );

    // this.locations.add(
    //   {id: 'B01', teamId: 'blue', name: 'Beijing City Center, China', population: 60, description: '', point: [0, 40.1], status: 'available', region: 'region', title: 'title', type: 'type'},
    //   {id: 'B02', teamId: 'blue', name: 'Beijing Military HQ', population: 60, description: '', point: [0, 40.2], status: 'available', region: 'region', title: 'title', type: 'type'},

    //   {id: 'A01', teamId: 'red', name: 'US Base #1', population: 60, description: '', point: [-75, 40.1], status: 'available', region: 'region', title: 'title', type: 'type'},
    //   {id: 'A02', teamId: 'red', name: 'US Base #2', population: 60, description: '', point: [-75, 40.2], status: 'available', region: 'region', title: 'title', type: 'type'},
    // );

  }

  addGameUpdate(update: Partial<GameUpdate>) {
    const ts = Date.now();
    this.updates.add({
      teamId: 'white',
      message: '',
      ...update,
      id: ts.toString(),
      time: this.clock.get().time,
      timestamp: ts,
      type: update.type || 'sit-rep',
    })
  }
  
}

export class EventLog {
  readonly events = new Collection<DataEvent>(e => e.id);

  constructor(private state: any) {
    let idCounter = 1;
    Object
    .keys(state)
    .forEach((key) => {
      let item = state[key] || state[key];
      if(item instanceof Collection) {
        item.on((event) => {
          this.events.add({
            id: idCounter++,
            modelName: key,
            timestamp: Date.now(),
            ...event
          });
        });
      } else if(item instanceof Single) {
        item.on((event) => {
          const items = this.events.getAll().filter(t => t.modelName === key);
          this.events.remove(...items);
          this.events.add({
            id: idCounter++,
            modelName: key,
            timestamp: Date.now(),
            ...event
          });
        });
      }
    })
  }

  apply(event: DataEvent) {
    if(this.state[event.modelName]) {
      this.state[event.modelName][event.action](event.item);
    }
  }

  reset() {
    this.events.reset();
  }

}
