import { CSSProperties } from 'react';

export interface LayoutProps {
  actor: any;
  actorStatus: any;
  actorActions: any;
  actorChildren: any;
}

const styles: {[style: string]: CSSProperties} = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    borderRight: '1px solid #D9D9D9',
    height: '100%',
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '125px',
    flexGrow: 1,
    height: '100%',
    flexShrink: 0
  },
  actor: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #D9D9D9',
    flexGrow: 1,
    height: '126px',
    maxHeight: '126px',
    minHeight: '126px',
    padding: '8px',
    gap: '8px',
  },
  actorStatus: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #D9D9D9',
    maxWidth: '200px',
    flexGrow: 1,
    height: '126px',
    maxHeight: '126px',
    minHeight: '126px',
    padding: '8px',
    gap: '8px',
  },
  actorChildren: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '8px',
    gap: '8px',
  },
  actorActions: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '200px',
    flexGrow: 1,
    padding: '8px',
    gap: '8px',
    overflowY: 'auto'
  },
};

export function Layout(props: LayoutProps) {
  return <div style={styles.root}>

    <div style={styles.leftColumn}>
      <div style={styles.actor}>
        {props.actor}
      </div>

      <div style={styles.actorChildren}>
        {props.actorChildren}
      </div>
      
      
    </div>
    <div style={styles.rightColumn}>
      <div style={styles.actorStatus}>
        {props.actorStatus}
      </div>
      <div style={styles.actorActions}>
        {props.actorActions}
      </div>
    </div>
  </div>
}