import { Actor } from "@wargamer/types";
import React from "react";

export interface ActorStatusProps {
  renderActorStatus: (actor: Actor) => JSX.Element;
  actor?: Actor
}

export function ActorStatus(props: ActorStatusProps) {
  const jsx = React.useMemo(() => {
    if(!props.actor) {
      return <></>;
    }

    return props.renderActorStatus(props.actor);
  }, [props.actor]);

  return <div>
    <h2>Status</h2>
    {jsx}
  </div>;
}
