import React from "react";
import { Actor } from "@wargamer/types";
import { ActorList } from '../ActorList';

export interface ActorChildrenProps {
  actors: Actor[];
  onSelect: (actorId: string) => void;
}

export function ActorChildren(props: ActorChildrenProps) {
  return <div style={{minHeight: 0, overflow: 'auto', display: 'flex', flexDirection: 'column', gap: 8}}>
    <ActorList actors={props.actors} onClick={(a) => props.onSelect(a?.id)}/>
  </div>;
}
