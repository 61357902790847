import { getRhumbLineBearing, getGreatCircleBearing, getPreciseDistance, computeDestinationPoint } from 'geolib';

import circle from '@turf/circle';

export type Point = [number, number];

export function getDistance(a: Point, b: Point) {

  if(a[0] - b[0] > 1) return 10000000;
  if(a[1] - b[1] > 1) return 10000000;

  return getPreciseDistance((getLibPoint(a)), getLibPoint(b));
}

export function getHeading(origin: Point, target: Point) {
  return getGreatCircleBearing(getLibPoint(origin), getLibPoint(target));
}

export function getInFlightPoint(origin: Point, target: Point, speed: number, time: number) {
  const o = getLibPoint(origin);
  const h = getGreatCircleBearing(origin, target);
  const currentDistance = speed * time;

  const result = computeDestinationPoint(o, currentDistance, h);
  return [result.longitude, result.latitude] as Point;
}

export function getFlightTime(origin: Point, target: Point, speed: number) {
  const o = getLibPoint(origin);
  const t = getLibPoint(target);
  const d = getPreciseDistance(o, t);

  return d / speed;
}

export function buildFlightPath(origin: Point, target: Point) {
  const o = getLibPoint(origin);
  const t = getLibPoint(target);
  const h = getGreatCircleBearing(origin, target);
  const distance = getPreciseDistance(o, t);
  const steps = 500;
  const stepDistance = distance / steps;

  const points: [number, number][] = []

  for (let i = 0; i < steps; i++) {
    const point = computeDestinationPoint(o, stepDistance * i, h);
    points.push([point.latitude, point.longitude]);
  }

  return points;
}

export function createCircleFromPoint(point: Point, radius: number) {
  return circle(point, radius, {
    steps: 100,
    units: 'meters',
  });
}


function getLibPoint(point: Point) {
  return { lon: point[0], lat: point[1] };
}