import { Single } from '@wargamer/lib';
import React from 'react';

export function useValue<T>(single: Single<T>) {
  const [values, setValue] = React.useState<T>(() => single ? single.get() : {} as any);

  React.useEffect(() => {
    const update = () => {
      setValue(single.get());
    }

    single.on(update);
    return () => {
      single.off(update);
    }
  }, [single]);

  return values;
}