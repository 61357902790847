import React from 'react';
import { Layout } from './Layout';
import { Breadcrumbs } from './Breadcrumbs';
import { ActorCard } from './ActorCard'
import { Actor } from '@wargamer/types';
import { ActorActions } from './ActorActions';
import { ActorStatus } from './ActorStatus';
import { ActorChildren } from './ActorChildren';
import { Actions } from '../ActionButton';
import { useMyTeam } from '../../hooks';

export interface ActorControlMenuProps {
  actors: Actor[];
  selectedActor: string;
  targetedActor: string;
  onSelectActor: (id: string) => void;
  onAction: (action: Actions) => void;
  renderActorStatus: (actor: Actor) => JSX.Element;
}

export function ActorControlMenu(props: ActorControlMenuProps) {
  const selectedActor = props.actors.find(a => a.id === props.selectedActor);
  const team = useMyTeam();
  const canAct = team.role === 'moderator' || team.id === selectedActor?.teamId;

  const state = React.useMemo(() => {
    const visibleActors = props.actors.filter(a => {
      if(team.role === 'moderator' || team.role === 'viewer') {
        return true;
      } else {
        return team.id === a.teamId;
      }
    });

    let actor = visibleActors.find(a => a.id === props.selectedActor);
    let actors: Actor[] = [];

    while(actor) {
      actors.push(actor);
      if(actor?.parentActor) {
        actor = visibleActors.find(a => a.id === actor?.parentActor);
      } else {
        actor = undefined;
      }
    }

    actor = actors[0];
    actors = actors.reverse();
    const breadcrumbs = actors.map(a => ({id: a.id, label: a.name}));
    const children = visibleActors.filter(a => {
      if(actor?.id) {
        return a.parentActor === actor?.id;
      } else {
        return !a.parentActor;
      }
    });

    breadcrumbs.splice(0,0, {id: '_root', label: 'Force'});

    return {
      actor,
      actors,
      breadcrumbs,
      children
    }
  }, [props.selectedActor, props.actors, team]);

  return <Layout
    actor={<>
      <Breadcrumbs items={state.breadcrumbs} onClick={props.onSelectActor}/>
      <ActorCard actor={selectedActor}/>
    </>}
    actorActions={<ActorActions
      onAction={props.onAction}
      canAttack={selectedActor?.canAttack && canAct}
      canMove={selectedActor?.canMove && canAct}
      canStop={selectedActor?.canStop && canAct}
      canDeselect={selectedActor ? true : false}
      canLocate={selectedActor && selectedActor.canLocate !== false ? true : false}
      canShutdown={selectedActor?.canShutdown && canAct}
    />}
    actorChildren={<ActorChildren
      actors={state.children}
      onSelect={(id => {
        props.onSelectActor(id);
    })}/>}
    actorStatus={<ActorStatus
      actor={state.actor}
      renderActorStatus={props.renderActorStatus}
    /> }
  />;
}