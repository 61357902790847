import React, { CSSProperties } from "react";
import { ModalView } from './ModalView';
import { Actor, Casualties } from '@wargamer/types';
import { useModal } from '../hooks';
import { calculateBlast } from '@wargamer/lib/dist/utils';

export function useViewWeaponModal() {
  const modal = useModal();
  const open = (weapon: Actor) => {
    modal.open(<ViewWeaponModal
      weapon={weapon}
      onClose={modal.close}
    />);
  }

  return {
    open,
    close: modal.close,
    isOpen: modal.isOpen,
    root: modal.root,
  };
}

interface ViewWeaponModalProps {
  weapon: Actor;
  onClose: () => void;
}

const buttonStyle: CSSProperties = {
  fontWeight: 'bold',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  width: 117,
  height: 40,
  borderRadius: .5,
  backgroundColor: '#D9D9D9'
};

function ViewWeaponModal(props: ViewWeaponModalProps) {

  const detonationType = props.weapon.detonationType || 'air';
  const destroyedActors: Casualties = props.weapon['casualties'] || null;

  if (props.weapon.blastRadius) {
    return <ModalView title="Weapon Details">
      <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 6, justifyContent: 'space-between' }}>
          <h2>{props.weapon.name}<br />
            <span style={{ textTransform: 'capitalize', fontSize: 'medium' }}>
              <span style={{ color: props.weapon.status === 'available' ? 'green' : 'gray' }}>{props.weapon.status}</span>
            </span>
            {props.weapon.image ?
              <div style={{ width: '100%', flexDirection: 'row', paddingTop: '10px' }}>
                <img src={props.weapon.image} style={{ height: "75px", width: "auto", maxWidth: '100%' }} />
              </div>
              :
              ''
            }
          </h2>
          <ul>
            <li>Weapon Id: <b>{props.weapon.id}</b></li>
            <li>Blast Radius (meters): {props.weapon.blastRadius}</li>
            <li>Range (km): {props.weapon.range}</li>
            <li>Speed (m/s): {props.weapon.speed}</li>
            <li>Force: {props.weapon.teamId}</li>
          </ul>
        </div>

        <div>
          <b>Casualty Details</b>
          {destroyedActors?.items?.length > 0 ? <>
            <div>Fatalities: {destroyedActors.fatalities.toLocaleString()}</div>
            <div>Injuries: {destroyedActors.injuries.toLocaleString()}</div>
            <div>Casualties: {destroyedActors.casualties.toLocaleString()}</div>
          </> : null}
          {props.weapon.likelyKills ? <>
            <div style={{padding: '8px 0px'}}>
              {props.weapon.likelyKills}
            </div>
          </> : <></> }
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button style={buttonStyle} onClick={props.onClose}>Close</button>
        </div>

      </div>
    </ModalView>;
  }


  return <ModalView title="Weapon Details">
    <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 6, justifyContent: 'space-between' }}>
        <h2>{props.weapon.name}<br />
          <span style={{ textTransform: 'capitalize', fontSize: 'medium' }}>
            <span style={{ color: props.weapon.status === 'available' ? 'green' : 'gray' }}>{props.weapon.status}</span>
          </span>
          {props.weapon.image ?
            <div style={{ width: '100%', flexDirection: 'row', paddingTop: '10px' }}>
              <img src={props.weapon.image} style={{ height: "75px", width: "auto", maxWidth: '100%' }} />
            </div>
            :
            ''
          }
        </h2>
        <ul>
          <li>Weapon Id: <b>{props.weapon.id}</b></li>
          <li>Kilotons: {props.weapon.kilotons}</li>
          <li>Range (km): {props.weapon.range}</li>
          {/* <li>Warhead: {props.weapon.warhead ? props.weapon.warhead : 'Not specified' }</li> */}
          <li>Speed (m/s): {props.weapon.speed}</li>
          <li>Force: {props.weapon.teamId}</li>
        </ul>
      </div>
      
      <div>
        <b>Casualty Details</b>
        {destroyedActors?.items?.length > 0 ? <>
          <div>Fatalities: {destroyedActors.fatalities.toLocaleString()}</div>
          <div>Injuries: {destroyedActors.injuries.toLocaleString()}</div>
          <div>Casualties: {destroyedActors.casualties.toLocaleString()}</div>
        </> : null}
        {props.weapon.likelyKills ? <>
          <div style={{padding: '8px 0px'}}>
            {props.weapon.likelyKills}
          </div>
        </> : <></> }
      </div>

      {props.weapon.status === 'expended' ?
        <div><b>Explosion Details</b>
          <div style={{ overflowY: 'auto', maxHeight: '42vh', fontSize: 'small' }}>
            <div style={{ borderBottom: '1px solid #ccc' }}></div>
            <table>
              <tr>
                <td style={{minWidth: '165px'}}>Detonation</td>
                <td colSpan={2} style={{minWidth: '70px'}}>{detonationType} burst</td>
              </tr>
              {detonationType === 'ground' ?
                <tr>
                  <td><span style={{ color: '#00FE0A' }}>⬤</span> Radiation</td>
                  <td style={{minWidth: "60px"}}>{Math.round(calculateBlast(props.weapon.kilotons, detonationType || 'air', 'RadiationRadius')) / 1000}km</td>
                  <td style={{ fontSize: 'small', padding: '8px' }}>500 rem ionizing radiation dose; likely fatal, in about 1 month; 15% of survivors will eventually die of cancer as a result of exposure.</td>
                </tr>
                : ''
              }
              <tr>
                <td><span style={{ color: '#FEE500' }}>⬤</span> Fire Ball</td>
                <td>{Math.round(calculateBlast(props.weapon.kilotons, detonationType || 'air' as any, 'FireballRadius')) / 1000}km</td>
                <td style={{ fontSize: 'small' }}>Maximum size of the nuclear fireball; relevance to damage on the ground depends on the height of detonation. If it touches the ground, the amount of radioactive fallout is significantly increased. Anything inside the fireball is effectively vaporized.</td>
              </tr>
              <tr>
                <td><span style={{ color: '#D11212' }}>⬤</span> Heavy Blast Damage</td>
                <td>{Math.round(calculateBlast(props.weapon.kilotons, detonationType || 'air' as any, 'HeavyBlastDamage')) / 1000}km</td>
                <td style={{ fontSize: 'small' }}>At 20 psi overpressure, heavily built concrete buildings are severely damaged or demolished; fatalities approach 100%.</td>
              </tr>
              <tr>
                <td><span style={{ color: '#8EBBFF' }}>⬤</span> Moderate Blast Damage</td>
                <td>{Math.round(calculateBlast(props.weapon.kilotons, detonationType || 'air' as any, 'ModerateBlastDamage')) / 1000}km</td>
                <td style={{ fontSize: 'small' }}>At 5 psi overpressure, most residential buildings collapse, injuries are universal, fatalities are widespread. The chances of a fire starting in commercial and residential damage are high, and buildings so damaged are at high risk of spreading fire.</td>
              </tr>
              <tr>
                <td><span style={{ color: '#CCCCCC' }}>⬤</span> Light Blast Damage</td>
                <td>{Math.round(calculateBlast(props.weapon.kilotons, detonationType || 'air' as any, 'LightBlastDamage')) / 1000}km</td>
                <td style={{ fontSize: 'small' }}>At a around 1 psi overpressure, glass windows can be expected to break. This can cause many injuries in a surrounding population who comes to a window after seeing the flash of a nuclear explosion (which travels faster than the pressure wave).</td>
              </tr>
              <tr>
                <td><span style={{ color: '#E4AD45' }}>⬤</span> Thermal Radiation Radius</td>
                <td>{Math.round(calculateBlast(props.weapon.kilotons, detonationType || 'air' as any, 'ThirdDegreeBurns')) / 1000}km</td>
                <td style={{ fontSize: 'small' }}>Third degree burns extend throughout the layers of skin, and are often painless because they destroy the pain nerves. They can cause severe scarring or disablement, and can require amputation.</td>
              </tr>
            </table>
          </div>
        </div>
        : ''
      }
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <button style={buttonStyle} onClick={props.onClose}>Close</button>
      </div>

    </div>
  </ModalView>;
}