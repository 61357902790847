import { LoginRequest } from "@wargamer/types";
import React, { CSSProperties } from "react"

export interface LoginScreenProps {
  onLogin: (request: LoginRequest) => void;
}

const inputStyle: CSSProperties = {
  height: 39,
  borderRadius: 5,
  backgroundColor: '#D9D9D9',
  border: 'none',
  padding: 4,
  boxSizing: 'border-box',
  borderRight: 'none',
}
const loginStyle: CSSProperties = {
  height: 39,
  borderRadius: 5,
  backgroundColor: '#3E3F46',
  border: 'none',
  borderRight: 'none',
  cursor: 'pointer',
  width: 122,
  fontWeight: 700,
  fontSize: 14,
  textAlign: 'center',
  color: '#DBCABD'
}

export function LoginScreen(props: LoginScreenProps) {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  const login = () => {
    props.onLogin({password, username});
  };

  return <div style={{
    display: 'flex',
    backgroundColor: 'black',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  }}>
    <div style={{
      height: 56,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <img src="/nsri-logo.png" style={{height: 42}}/>
    </div>
    <div style={{
      flex: 1,
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'url(/background_v2.png)',
      gap: 24
    }}>

      <div style={{fontSize: 36, textAlign: 'center', color: 'white'}}>
        Escalation Management of Taiwan Crisis
      </div>

      <div style={{
        height: 350,
        width: 609,
        border: '1px solid white',
        borderRadius: 12,
        overflow: 'hidden',
        backgroundColor: 'black'
      }}>
        <div style={{backgroundColor: '#3E3F46', display: 'flex', justifyContent: 'center', height: 60, alignItems: 'center'}}>
          <img src="/logo.png" style={{height: 51}}/>
          <img src="/logo-text.png" style={{height: 51}}/>
        </div>
        <div style={{gap: 12, display: 'flex', flexDirection: 'column', padding: 10}}>
          <div style={{fontSize: 20, textAlign: 'center', color: '#CC4254'}}>
            Authorization Required
          </div>
          <div style={{display: 'flex', flexDirection: 'column', gap: 5, marginLeft: 20, marginRight: 20}}>
            <label style={{fontSize: 14, fontWeight: 'bold'}}>Username</label>
            <input style={inputStyle} value={username} onChange={e => setUsername(e.target.value)}/>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', gap: 8, marginLeft: 20, marginRight: 20}}>
            <label style={{fontSize: 14, fontWeight: 'bold'}}>Password</label>
            <input style={inputStyle}
              value={password}
              type={'password'}
              onChange={e => setPassword(e.target.value)}
              onKeyUp={e => e.key === 'Enter' && login()}
            />
          </div>
          <div style={{display: 'flex', justifyContent: 'center', padding: 5}}>
            <button style={loginStyle} onClick={login} disabled={!password || !username}>LOGIN</button>
          </div>
        </div>
      </div>

    </div>

    <div style={{
      height: 80,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 8
    }}>
      <img src="/nnsa-logo.png" style={{height: 33, width: 'auto'}}/>
      <div style={{color: 'white', fontSize: 12}}>
      Copyright © 2023 Optimal Outcomes. All Rights Reserved.
      </div>
    </div>
  </div> 
}