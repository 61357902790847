import {
  createBrowserRouter,
  RouterProvider,
  redirect,
  Link
} from "react-router-dom";
import { GameApp } from './GameApp';
import { LoginPage } from './LoginPage';

const router = createBrowserRouter([
  {
    path: "/", children: [
      {
        path: "/game/:gameId/:playerId",
        element: <GameApp/>,
      },
      {
        path: "/dbg",
        element: <div style={{display: 'flex', gap: 8, padding:8}}>
          <button><Link to="/game/abc123/white">White Player</Link></button>
          <button><Link to="/game/abc123/red">Red Player</Link></button>
          <button><Link to="/game/abc123/blue">Blue Player</Link></button>
        </div>
      },
      {
        path: "",
        element: <LoginPage/>
      }
    ]
  }
]);

function App() {

  return <RouterProvider router={router} />;
}

export default App;
