import { Actor, ActorMapItem, ActorMapState } from "@wargamer/types";
import { Feature } from "geojson";
import mapboxgl from "mapbox-gl";

export interface MapClickEvent {
  type: 'primary' | 'context' | 'selection';
  point: [number, number];
  cursor: [number, number];
  actors: Actor[];
}

export class MapboxHelper {

  private differ = new Differ();
  private actors: Actor[] = [];
  private selectedActor?: string;
  private targetedActor?: string

  constructor(
    private map: mapboxgl.Map,
    private renderMapIcon: (actor: Actor, state: ActorMapState) => Promise<ActorMapItem>,
    private onClick: (event: MapClickEvent) => void
  ) {
    setInterval(() => {
      const actors = this.actors.filter(a => a.status === 'en-route');
      if(actors.length) {
        this.update(this.actors, this.selectedActor, this.targetedActor);
      }
    }, 60);
  }
  async rerender() {
    await this.update(this.actors, this.selectedActor, this.targetedActor, true);
  }

  async update(actors: Actor[], selectedActor?: string, targetedActor?: string, forced?: boolean) {
    this.actors = actors;

    const iconFeatures: Feature[] = [];
    const shapeFeatures: Feature[] = [];

    const mapItems = (await Promise.all(
      actors
      .sort((a, b) => {
        return getActorMapOrder(b, selectedActor, targetedActor) - getActorMapOrder(a, selectedActor, targetedActor);
      })
      .map(a => this.renderMapIcon(a, {
        selected: a.id === selectedActor,
        targeted: a.id === targetedActor,
        forcedRerender: forced || a.id === this.selectedActor  || a.id === this.targetedActor
      }))
    )).filter(item => item);

    this.selectedActor = selectedActor;
    this.targetedActor = targetedActor;

    mapItems.forEach(mapItem => {
      
      Object.keys(mapItem.icons || {}).forEach(id => {
        const image = mapItem.icons[id];
        if(this.differ.setValue(id, image)) {
          if(this.map.hasImage(id)) {
            this.map.updateImage(id, image as any);
          } else {
            this.map.addImage(id, image as any);
          }
        }
      });

      mapItem.actorFeatures.forEach(feature => {
        const type = feature?.properties.type || 'icon';

        if(type == 'icon') {
          iconFeatures.push(feature);
        } else if(type === 'ring') {
          shapeFeatures.push(feature);
        }
      })

    });

    const itemsSource = this.map.getSource('items') as mapboxgl.GeoJSONSource;
    itemsSource.setData({
      type: 'FeatureCollection',
      features: iconFeatures,
    });

    const ringsSource = this.map.getSource('ring_items') as mapboxgl.GeoJSONSource;
    ringsSource.setData({
      type: 'FeatureCollection',
      features: shapeFeatures,
    });
  }
  public isSet = false;

  addAllLayers() {
    const map = this.map;
    map.addSource('items', {
      type: 'geojson',
      buffer: 0,
      data: {
        type: 'FeatureCollection',
        features: []
      }
    });

    map.addSource('ring_items', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    });

    map.addSource('range_rings', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    });

    map.addSource('draw_features', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    });

    map.addLayer({
      id: 'rings_layer',
      source: 'ring_items',
      type: 'fill',
      layout: { },
      paint: {
        "fill-color": ["get", "color"],
        // "fill-outline-color": ["get", "outlineColor"],
      }
    });

    map.addLayer({
      id: 'ring_outlines_layer',
      source: 'ring_items',
      type: 'line',
      layout: { },
      paint: {
        "line-color": ["get", "outlineColor"],
        "line-width": 3
      }
    });

    // this is supposed to show text only when zoomed in significantly
   
    map.addLayer({
      id: 'items_layer',
      source: 'items',
      type: 'symbol',
      layout: {
        "icon-image": ["get", "icon"],
        "icon-allow-overlap": true,
        "text-allow-overlap": true,
        "icon-size": [
          'interpolate',
          ['exponential', 0.5],
          ['zoom'],
          5  , 0.5,
          18 , 1
        ],
        'icon-rotate': ['get', 'heading'],
        'icon-rotation-alignment': 'map',
        'symbol-z-order': 'source'
      },
      paint: { }
    });

    // map.addLayer({
    //   id: 'items_layer_circle',
    //   source: 'items',
    //   type: 'circle',
    //   paint: {
    //     "circle-radius": 4,
    //     "circle-stroke-width": 1
        
    //   },
    // });

    map.addLayer({
      id: 'range_rings_layer',
      source: 'range_rings',
      type: 'line',
      layout: { },
      paint: {
        "line-color": '#3860AF',
        "line-width": 3,
        // 'line-gap-width': 2,
        // 'line-dasharray': [2]
      }
    });

    map.addLayer({
      id: 'draw_features',
      source: 'draw_features',
      type: 'line',
      layout: { },
      paint: {
        "line-color": 'red',
        "line-width": 2
      }
    });

    let clickTimer: any = null;

    const onClick = (
      type: 'primary' | 'context' | 'selection',
      e: mapboxgl.MapMouseEvent,
      features: mapboxgl.MapboxGeoJSONFeature[]
    ) => {
      console.log(e.lngLat);
      clearTimeout(clickTimer);
      const items = features.map(f => {
        const id = f.properties?.id;
        if(id) {
          return this.actors.find(item => item.id === id) || null;
        } else {
          return null;
        }
      }).filter(i => i) as Actor[];

      this.onClick({
        point: [e.lngLat.lng, e.lngLat.lat],
        cursor: [e.originalEvent.clientX,e.originalEvent.clientY],
        type: type,
        actors: items
      });

    };

    map.on('click', (e) => {
      clickTimer = setTimeout(() => {
        onClick('primary', e, []);
      });
    });

    map.on('click', 'items_layer', (e) => {
      onClick('primary', e, e.features || []);
    });

    map.on('contextmenu', (e) => {
      clickTimer = setTimeout(() => {
        onClick('context', e, []);
      });
    });

    map.on('contextmenu', 'items_layer' ,(e) => {
      onClick('context', e, e.features || []);
    });

    map.addLayer({
      id: 'items_text_layer',
      source: 'items',
      type: 'symbol',
      minzoom: 9,
      filter: ["has", "name"],
      layout: {
        "text-field": ["get", "name"],
        "text-size": 11,
        "icon-allow-overlap": true,
      }
    });

    this.differ.reset();

  }

  resetImageDiffer() {
    this.differ.reset();
  }
}

class Differ {
  private known = new WeakSet<object>();
  private map = new Map<string, WeakRef<object>>();
  inputArray<T extends object>(collection: T[]): T[] {
    return collection.filter(item => {
      return this.inputObject(item);
    }).filter(i => i);
  }

  inputObject<T extends object>(object: T): T | null {
    if(!object) {
      return null;
    }

    if(this.known.has(object)) {
      return null;
    }

    this.known.add(object);

    return object;
  }

  setValue<T extends object>(key: string, object: T) {
    if(!this.map.get(key) || this.map.get(key)?.deref() !== object) {
      const ref = new WeakRef(object);
      this.map.set(key, ref);
      return object;
    }
    
    return null;
  }

  reset() {
    this.known = new WeakSet<object>();
    this.map = new Map<string, WeakRef<object>>();
  }
}


function getActorMapOrder(actor: Actor, selectedActor?: string, targetedActor?: string) {
  if(actor.id ===  selectedActor) {
    return 0;
  } else if(actor.id ===  targetedActor) {
    return 10;
  } else if(actor.status === 'destroyed') {
    return 1000;
  } else {
    return 100;
  }
}