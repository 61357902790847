import { Chat, XChatMessage } from './Chat';
import { Panel } from './Panel';
import { Avatar } from './Avatar';
import { Freeze } from './Freeze';
import React from 'react';
import { Team } from '@wargamer/types';

export interface ChatViewProps {
  chatPlayerId: string;
  chats: Team[];
  onSend: (msg: string, to: string) => void;
  messages: XChatMessage[];
}

export function ChatView(props: ChatViewProps) {
  const chats = props.chats.filter(c => c.id !== props.chatPlayerId);
  const [selected, setSelected] = React.useState(() => chats[0]?.id);
  const tsRef = React.useRef<{[key: string]: number}>({});
  const selectedTeam = props.chats.find(c => c.id === selected);

  const avatars = chats.map((chat) => {
    const hasNotifications = props.messages.find((m) => m.from === chat.id && m.to === props.chatPlayerId && m.ts > tsRef[chat.id]) ? true : false;

    return <Avatar
      key={chat.id}
      team={chat.name as any}
      size='27'
      color={chat.color}
      selected={chat.id === selected}
      notification={hasNotifications}
      onClick={() => {
        tsRef.current[chat.id] = Date.now();
        setSelected(chat.id)
      }}/>
  });
  const containers = chats.map((chat) => {
    return <Freeze
      key={chat.id}
      freeze={chat.id !== selected}
    >
      <Chat
        onSend={(msg) => props.onSend(msg, chat.id)}
        messages={props.messages
          .filter((m) => (m.from === props.chatPlayerId && m.to === chat.id) || (m.to === props.chatPlayerId && m.from === chat.id))}
      />
    </Freeze>  
  });


  return <div style={{display: 'flex', flexDirection: 'row', flexGrow: 1}}>
    <div style={{width: '40px', backgroundColor: 'black', display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'center', paddingTop: '13px', boxSizing: 'border-box'}}>

      {avatars}

    </div>
    <Panel
      title={<>
          <img src="/icons/CableLogo.svg" style={{height: '11px', width: '52px'}}/>
          <div style={{fontWeight: 'bold', fontSize: '14px', color: 'white'}}>{selectedTeam?.name}</div>
        </>}
      style={{
        padding: 0,
      }}
      titleStyle={{
        border: 'none'
      }}
    >
      {containers}
    </Panel>
  </div>;
}
