import React, { CSSProperties } from "react";
import { ModalView } from './ModalView';
import { useModal } from '../hooks';

export function useMapLayersModal() {
  const modal = useModal();
  const open = (props: MapLayersModalProps) => {
    modal.open(<MapLayersModal
      {...props}
      onChange={(s) => {
        modal.close();
        props.onChange(s);
      }}
    />);
  }

  return {
    open,
    close: modal.close,
    isOpen: modal.isOpen,
    root: modal.root,
  };
}

export interface MapLayerSettings {
  mapStyle?: 'default' | 'satellite';
  iconStyle?: 'civ' | 'mil';
}

interface MapLayersModalProps {
  settings: MapLayerSettings;
  onChange: (change: MapLayerSettings) => void;
}

const buttonStyle: CSSProperties = {
  fontWeight: 'bold',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  width: 117,
  height: 40,
  borderRadius: .5,
  backgroundColor: '#D9D9D9'
};

export function getDefaultMapLayerSettings(): MapLayerSettings {
  return {
    mapStyle: 'default',
    iconStyle: 'civ'
  }
}

function MapLayersModal(props: MapLayersModalProps) {

  const [settings, setSettings] = React.useState(props.settings || {});

  return <ModalView title="Map Layer Options">
    <div style={{ display: 'flex', flexDirection: 'column', gap: 24, height: '100%' }}>

      <div style={{padding: '10px', borderRadius: '4px', flexGrow: 1}}>
        <h3>Map Style</h3>
        <label>
          <input
            type='radio'
            name='mayStyle'
            checked={settings.mapStyle === 'default'}
            onChange={() => setSettings(s => ({
              ...s,
              mapStyle: 'default'
            }))}></input>
          Default
        </label>
        <label>
          <input
            type='radio'
            name='mayStyle'
            checked={settings.mapStyle === 'satellite'}
            onChange={() => setSettings(s => ({
              ...s,
              mapStyle: 'satellite'
            }))}></input>
          Satellite
        </label>

        <hr/>

        <h3>Icon Styles</h3>
        <label>
          <input
            type='radio'
            name='iconStyle'
            checked={settings.iconStyle === 'civ'}
            onChange={() => setSettings(s => ({
              ...s,
              iconStyle: 'civ'
            }))}></input>
          Civilian
        </label>
        <label>
          <input
            type='radio'
            name='iconStyle'
            checked={settings.iconStyle === 'mil'}
            onChange={() => setSettings(s => ({
              ...s,
              iconStyle: 'mil'
            }))}></input>
          MilSymbol
        </label>
      </div>

      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row'}}>
        <button style={buttonStyle} onClick={() => props.onChange(props.settings)}>Cancel</button>
        <button style={buttonStyle} onClick={() => props.onChange(settings)}>Apply</button>
      </div>

    </div>
  </ModalView>;
}