import { Collection } from '@wargamer/lib';
import React from 'react';

export function useCollectionValues<T>(collection: Collection<T>) {
  const [values, setValue] = React.useState<T[]>(() => collection ? collection.getAll() : []);

  React.useEffect(() => {
    const update = () => {
      setValue(collection.getAll());
    }

    collection.on(update);
    return () => {
      collection.off(update);
    }
  }, [collection]);

  return values;
}

export function useCollectionValue<T>(collection: Collection<T>, id: string) {
  const [value, setValue] = React.useState<T>(() => collection ? collection.get(id) : null);

  React.useEffect(() => {
    let pValue = value;
    const update = () => {
      const nValue = collection ? collection.get(id) : null;
      if(nValue !== pValue) {
        pValue = nValue;
        setValue(nValue);
      }
    }

    collection.on(update);
    return () => {
      collection.off(update);
    }
  }, [collection]);

  return value;
}