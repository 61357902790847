import { getFlightTime, getInFlightPoint, getHeading, getActorParents } from "@wargamer/lib/dist/utils";
import { Action, Actor, IGameState } from "@wargamer/types";

export function isActorMoving(actor: Actor) {
  return actor.status === 'en-route';
}

export function getMoveStartAndDestination(actor: Actor, action: Action, state: IGameState) {
  const clock = state.clock.get();
  const actors = state.assets.getAll();
  const start = getMoveCurrent(actor, clock.time, actors)?.point;
  let dest: [number, number] = null;

  if(action.targetedActorId) {
    const destActor = actors.find(a => a.id === action.targetedActorId);
    dest =  getMoveCurrent(destActor,clock.time, actors)?.point;
  }

  if(!dest && action.point) {
    dest = action.point;
  } 

  return {
    start,
    dest
  }
}

export function moveStart(actor: Actor, action: Action, state: IGameState) {
  if(!actor.canMove && !actor.canAttack) {
    throw new Error(`${actor.name} is not movable`);
  }

  if(actor.status === 'destroyed') {
    throw new Error(`${actor.name} is destroyed and cannot be moved`);
  }
  const clock = state.clock.get();
  const {start,dest} = getMoveStartAndDestination(actor, action, state);

  if(!dest) {
    throw new Error(`Could not determine destination point`);
  }

  const flightTime = getFlightTime(start, dest, actor.moveSpeed);

  state.assets.update({
    id: actor.id,
    status: 'en-route',
    point: start,
    moveDestination: dest,
    moveStart: clock.time,
    moveEta: clock.time + flightTime
  });

  return true;
}

function getActorLocation(actor: Actor, actors: Actor[]) {
  let start = actor.point;

  if(!start) {
    let a = actor;
    let x = 0;
    while(!start) {
      a = actors.find(x => x.id === a.parentActor);
      start = a?.point;
      x++;
      if((!start && !a?.parentActor) || x > 5) {
        throw new Error(`Cannot determine start point for ${actor.id}`);
      }
    }
  }

  return start;
}

export function moveEnd(actor: Actor, state: IGameState) {
  const clock = state.clock.get();
  let point = actor.moveDestination || actor.point;

  if(clock.time < actor.moveEta) {
    const flightTime = clock.time - actor.moveStart;
    point = getInFlightPoint(actor.point, actor.moveDestination, actor.moveSpeed, flightTime);
  }

  state.assets.update({
    id: actor.id,
    status: 'parked',
    moveDestination: null,
    moveStart: 0,
    moveEta: 0,
    point
  });
}

export function moveTick(actor: Actor, state: IGameState, onArrive: () => void) {
  const clock = state.clock.get();

  if(clock.time >= actor.moveEta) {
    state.assets.update({
      id: actor.id,
      status: 'parked',
      moveDestination: null,
      moveStart: 0,
      moveEta: 0,
      point: actor.moveDestination
    });

    onArrive();
  }
}

export function getMoveCurrent(actor: Actor, time: number, actors: Actor[] = []): {point: [number, number], heading: number} {
  if(actor.status !== 'en-route') {
    if(actor.point) {
      return {
        point: actor.point,
        heading: 0
      };
    } else if(actors?.length && actor.parentActor) {
      const actorMap = memoActorMap(actors);
      return getMoveCurrent(actorMap.get(actor.parentActor), time, actors);
    } else {
      return {
        point: null,
        heading: null
      }
    }
  }

  let point = actor.moveDestination;

  if(time < actor.moveEta) {
    const flightTime = time - actor.moveStart;
    point = getInFlightPoint(actor.point, actor.moveDestination, actor.moveSpeed, flightTime);
  }

  const heading = actor.moveDestination ? getHeading(point, actor.moveDestination) : 0;

  return {
    point, 
    heading
  }
}

const actorMapCache = new WeakMap<Actor[], Map<string, Actor>>()
function memoActorMap(actors: Actor[]) {
  if(!actorMapCache.has(actors)) {
    const map = new Map<string, Actor>();
    actors.forEach(a => map.set(a.id, a));
    actorMapCache.set(actors, map);
  }

  return actorMapCache.get(actors);
}