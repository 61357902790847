import React, { createContext, useContext } from 'react';
import { GameState } from '@wargamer/lib';
import { ActorRendererCollection } from '@wargamer/lib';
import { actorRendererList } from '@mage/actors';

const DEFAULT: GameStateContextData = {
  gameState: new GameState(),
  gameId: '',
  playerId: '',
  playerTeam: '',
  playerToken: '',
  set: () => {},
  actorRenderer: null
}

DEFAULT.actorRenderer = new ActorRendererCollection(DEFAULT.gameState, []);
const GameStateContext = createContext<GameStateContextData>(DEFAULT);

export interface GameStateContextData {
  gameState: GameState;
  gameId: string;
  playerId: string;
  playerTeam: string;
  playerToken: string;
  set:(updates: Partial<GameStateContextData>) => void;
  actorRenderer: ActorRendererCollection;
}

export interface GameStateProviderProps {
  children: any;
  context: GameStateContextData;
}

export function createNewContext() {
  const state = new GameState();
  return {
    ...DEFAULT,
    gameState: state,
    actorRenderer: new ActorRendererCollection(state, actorRendererList)
  }
}

export function GameStateProvider(props: GameStateProviderProps) {

  const [value, update] = React.useState(props.context);
  value.set = (updates: Partial<GameStateContextData>) => update(current => ({...current, ...updates}));
  
  return <GameStateContext.Provider value={value}>
    {props.children}
  </GameStateContext.Provider>
}

export function useGameSession() {
  return useContext(GameStateContext);
}

export function useGameState() {
  return useGameSession()?.gameState;
}