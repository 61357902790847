import { LoginScreen } from './components';
import { login } from './api/auth';
import { useAsync } from './hooks';
import { useNavigate } from 'react-router-dom';

export function LoginPage() {

  const nav = useNavigate();

  const loginCall = useAsync(login, {
    onError: () => {
      alert('Invalid username or password')
    },
    onSuccess: (result) => {
      nav(`/game/${result.gameId}/${result.playerId}`);
    }
  });

  return <LoginScreen onLogin={loginCall.call}/>
}