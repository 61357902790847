import './Layout.scss';

export interface LayoutProps {
  header: any;
  main: any;
  right: any;
  bottom: any;
}

export function Layout(props: LayoutProps) {
  return <div className='wg-layout'>
    <div style={{height: 41, minHeight: 41}}>
      {props.header}
    </div>
    <div style={{flexGrow: 1, flexDirection: 'row'}}>
      <div style={{flex: 1}}>
        <div style={{flex: 1}}>
          {props.main}
        </div>
        <div>
          {props.bottom}
        </div>
      </div>
      <div style={{width: 338}}>
        {props.right}
      </div>
    </div>
  </div>
}