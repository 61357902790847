import { useGameSession } from '../hooks';

export interface HeaderProps {

}

export function Header(props: HeaderProps) {
  const session = useGameSession();
  
  return <div style={{
    width: '100%',
    height: 40,
    borderBottom: '1px solid #000',
    backgroundColor: '#3E3F46',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }}>
    <div style={{display: 'flex', flexDirection: 'row'}}>
      <img src="/logo.png" style={{height: 40, width: 40}}/>
      <img src="/logo-text.png" style={{height: 40, width: 55}}/>
    </div>
    <div style={{padding: '10px', color: '#fff', textTransform: 'uppercase', fontSize: '16px', fontWeight: 'bold'}}>
      {session.playerId}
    </div>
    <div style={{display: 'flex', flexDirection: 'row'}}>
      <div style={{padding: '10px'}}>
        <a href="/" style={{color: 'white', textDecoration: 'none'}}>Logout</a>
      </div>
      <div style={{width: 40, height: 40, border: '1px solid #ccc', textTransform: 'uppercase', fontSize: '24px', textAlign: 'center', paddingTop: '2px' }}>
        {session.playerId.charAt(0)}
      </div>
    </div>
  </div>
}