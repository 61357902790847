import React from "react"
import { Avatar } from './Avatar';
import { ChatMessage } from '@wargamer/types';

export interface ChatProps {
  onSend: (msg: string) => void;
  messages: XChatMessage[];
}

export interface XChatMessage extends ChatMessage {
  fromMe: boolean; 
}

export function Chat(props: ChatProps) {
  const [msg, setMsg] = React.useState('');

  let elements = props.messages.map((m, i) => {
    return <ChatMessageBubble key={i} message={m}/>;
  });

  const sendMessage = () => {
    if(msg.trim()) {
      props.onSend(msg)
    }
    setMsg('');
  }

  return <div style={{flex: 1}}>
    <div style={{
      maxHeight: '100%',
      overflowY: 'auto',
      padding: 12,
      gap: 12,
      flex: 1,
    }}>
      {elements}
    </div>
    <div style={{
      backgroundColor: 'black',
      padding: 12,
      gap: 12,
      flexDirection: 'row'
    }}>
      <input
        style={{flex: 1, borderRadius: '4px'}}
        value={msg}
        placeholder="Send a cable"
        onChange={(e) => setMsg(e.target.value)}
        onKeyUp={e => e.key === 'Enter' && sendMessage()}/>
      
      <button onClick={sendMessage}>SEND</button>
    </div>
  </div>
}

interface ChatMessageBubbleProps {
  message: XChatMessage
}

function ChatMessageBubble(props: ChatMessageBubbleProps) {

  let avatar = <Avatar team={props.message.from as any}/>
  let filler = <div style={{width: 42, height: 42}}></div>;

  const date = React.useMemo(() => {
    if(!props.message?.ts) {
      return '';
    }

    const date = new Date(props.message.ts);
    return date.toLocaleTimeString();
  }, [props.message?.ts]);

  return <div style={{
    flexDirection: 'row',
    gap: 12,
    height: 'fit-content',
    flexShrink: 0
  }}>

    {props.message.fromMe !== true ? avatar : filler}

    <div style={{
      backgroundColor: '#7E7F85',
      borderRadius: 4,
      padding: 12,
      flex: 1,
      minHeight: 42,
      boxSizing: 'border-box',
      textAlign: props.message.fromMe ? 'right' : 'left',
      gap: 12,
      paddingBottom: 4,
      height: 'fit-content'
    }}>
      {props.message.message} <br/>
      <span style={{fontSize: 10}}>{date}</span>
    </div>

    {props.message.fromMe === true ? avatar : filler}

  </div>
}


