import React from "react";
import './ActorIcon.scss';
import { useCssVariables } from '../hooks';

export interface ActorIconProps {
  style?: 'default' | 'flat';
  size?: 'default' | 'large';
  color?: string;
  children?: any;
}

export function ActorIcon(props: ActorIconProps) {
  const className = React.useMemo(() => {
    return `asset-icon ${props.style || 'default'} ${props.size || 'default'}`;
  }, [props.size, props.style]);

  const ref = useCssVariables({
    '--color': props.color || 'gray'
  })

  return <div className={className} ref={ref}>
    {props.children}
  </div>;
}
