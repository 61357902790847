import { Actor, Team } from "@wargamer/types";
import { useFindActorModal } from './FindActorModal';
import './FindActorButton.scss';

export interface FindActorButtonProps {
  actors: Actor[];
  teams: Team[];
  onActor: (actor: Actor) => void;
  renderActorIcon: (actor: Actor) => any;
}

export function FindActorButton(props: FindActorButtonProps) {
  const modal = useFindActorModal();
  const open = () => {
    modal.open({
      actionLabel: 'Select',
      subTitle: '',
      actors: props.actors,
      onSelection: props.onActor,
      teams: props.teams,
      title: 'Find Asset',
    })
  }
  return <>
    <div dangerouslySetInnerHTML={{__html: svg}} onClick={open} className="find-actor-button"></div>
    {modal.root}
  </>;
}

const svg = `
<svg width="32" height="32" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.32985 18.9842C7.32985 12.5477 12.5478 7.32982 18.9843 7.32982C25.4208 7.32982 30.6388 12.5477 30.6388 18.9842C30.6388 25.4206 25.4208 30.6385 18.9843 30.6385C12.5478 30.6385 7.32985 25.4206 7.32985 18.9842ZM18.9843 4C10.7089 4 4 10.7088 4 18.9842C4 27.2595 10.7089 33.9683 18.9843 33.9683C22.5223 33.9683 25.7737 32.742 28.3374 30.6918L38.1579 40.5122C38.8079 41.1626 39.8623 41.1626 40.5122 40.5122C41.1626 39.8623 41.1626 38.8079 40.5122 38.1579L30.6917 28.3375C32.7419 25.7739 33.9683 22.5225 33.9683 18.9846C33.9683 10.7093 27.2598 4 18.9843 4Z" fill="white"/>
</svg>
`;