import React from 'react';

// declare type Awaited<T> = T extends PromiseLike<infer U> ? U : T;

export interface UseAsyncOptions<T extends ((...args: any[]) => any)> {
  onBefore?: (...args: any) => Promise<boolean>;
  onError?: (error: any, ...args: any) => void;
  onSuccess?: (value: Awaited<ReturnType<T>>) => void;
}

export function useAsync<T extends ((...args: any[]) => any)>(method: T, options: UseAsyncOptions<T> = {}) {
  const [loading, setLoading] = React.useState(false)
  const [value, setValue] = React.useState<Awaited<ReturnType<T>>>(null as any);
  const [error, setError] = React.useState<any>(null);
  const methodRef = React.useRef(method);
  methodRef.current = method

  const call: (...args: Parameters<T>) => ReturnType<T> = React.useCallback(async (...args: any) => {
    setLoading(true);
    try {
      if (options?.onBefore && await options.onBefore(...args) === false) {
        return;
      }
      const newValue = await methodRef.current(...args);
      setValue(newValue);
      setLoading(false);
      options?.onSuccess && options.onSuccess(newValue);
      return newValue;
    } catch (err) {
      console.error(err);
      setError(err);
      setLoading(false);
      options?.onError && options.onError(err);
    }
    return null;
  }, [method]) as any;

  return {
    loading,
    value,
    call,
    error,
    setValue
  }
}