import axios from 'axios';
import { API_HOST } from '../config';
import { LoginRequest, LoginResult, PlayerToken } from '@wargamer/types';

export function getPlayerToken(gameId: string, playerId?: string) {
  return axios.get<PlayerToken>(`${API_HOST}/game/${gameId}/get-player-token?playerId=${playerId}`).then(r => r.data);
}


export function login(request: LoginRequest) {
  return axios.post<LoginResult>(`${API_HOST}/game-login`, request).then(r => r.data);
}
