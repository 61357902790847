import { GameClock, IGameMainController, IActorMainController, Actor, ActorMainClassConstructor } from '@wargamer/types';

export class ActorMainCollection implements IActorMainController<Actor> {

  readonly name = 'MainAssetHandler';

  private readonly classMap: {[name: string]: IActorMainController<Actor>} = {};
  private game: IGameMainController;

  constructor(
    private classList: ActorMainClassConstructor[],
  ) { }

  init(game: IGameMainController) {
    this.game = game;
    this.classList.forEach((cls) => {
      const instance = new cls(game);
      if(!instance.name) {
        throw new Error(`${cls['name']} does not have a valid name property.`);
      }

      if(this.classMap[instance.name]) {
        throw new Error(`${cls['name']} and ${this.classMap[instance.name]['constructor']['name']} cannot have the same names.`);
      }

      this.classMap[instance.name] = instance;
    });
  }

  get(name: string) {
    return this.classMap[name];
  }

  validate(asset: Actor) {
    const instance = this.get(asset.actorClass);
    if(instance?.validate) {
      return instance.validate(asset);
    }

    return false;
  }

  tick(asset: Actor, clock: GameClock) {
    const instance = this.get(asset.actorClass);
    if(instance?.tick) {
      instance.tick(asset, clock);
    }
  }

  attack(asset: Actor, options: any): void {

    if(this.game.state.clock.get().state !== 'running') {
      throw new Error('clock not running');
    }

    const instance = this.get(asset.actorClass);
    if(instance?.attack) {
      instance.attack(asset, options);
    } else {
      throw new Error(`asset with id ${asset.id} and class ${asset.actorClass} has no registered class handler`);
    }
  }

  move(asset: Actor, options: any): void {
    const instance = this.get(asset.actorClass);
    if(instance?.move) {
      instance.move(asset, options);
    }
  }

  stop(asset: Actor): void {
    const instance = this.get(asset.actorClass);
    if(instance?.stop) {
      instance.stop(asset, {} as any);
    }
  }

  shutdown(asset: Actor): void {
    const instance = this.get(asset.actorClass);
    if(instance?.shutdown) {
      instance.shutdown(asset);
    }
  }

}