import { EventEmitter } from 'events';

EventEmitter.defaultMaxListeners = 0;

if(EventEmitter.setMaxListeners) {
  EventEmitter.setMaxListeners(0);
}

export class Collection<T> {

  private _map: {[key: string]: T} = {};
  readonly _events = new EventEmitter();

  constructor(
    private idExtractor: (item: T) => string | number
  ) { }

  add<TR extends T = T>(...items: TR[]) {
    items.forEach(item => {
      const key = this.idExtractor(item)
      
      if(!key) {
        console.warn('Could not get key for item', item);
        return;
      }

      if(this._map[key]) {
        this.update(item);
      } else {
        this._map[key] = item;
        this._events.emit('event', {item, action: 'add'});
      }
    })
  }

  reset() {
    this._map = {};
  }

  update<TR extends T = T>(...items: Partial<TR>[]) {
    items.forEach(item => {
      const key = this.idExtractor(item as any)
      
      if(!key) {
        console.warn('Could not get key for item', item);
        return;
      }

      if(this._map[key]) {
        this._map[key] = {
          ...this._map[key],
          ...item,
        }
        this._events.emit('event', {item, action: 'update'});
      } else {
        console.warn('Item does not exist!');
        this.add(item as any);
      }
    })
  }


  remove<TR extends T = T>(...items: TR[]) {
    items.forEach(item => {
      const key = this.idExtractor(item)
      
      if(!key) {
        console.warn('Could not get key for item', item);
        return;
      }

      const original = this._map[key];

      if(original) {
        delete this._map[key];
        this._events.emit('event', {item: original, action: 'remove'});
      }
    })
  }

  get<TR extends T = T>(id: string | number): TR {
    return this._map[id] as TR;
  }

  getAll<TR extends T = T>(): TR[] {
    return Object.keys(this._map).map(id => this._map[id]).filter(item => item) as TR[];
  }

  on(fn: (event: {item: T, action: 'add' | 'update' | 'remove'}) => void) {
    this._events.on('event', fn);
  }
  off(fn: (event: {item: T, action: 'add' | 'update' | 'remove'}) => void) {
    this._events.off('event', fn);
  }
}