const calcCache: {[key: string]: number} = {};

export function calculateBlast(kt: number, detonation: 'ground' | 'air', type: keyof DetonationEquation) {
  const key = `${kt}-${detonation}-${type}`;
  if(calcCache[key]) {
    return calcCache[key]
  }

  calcCache[key] = equations[detonation][type](kt) * 1000;

  return calcCache[key];
}

const equations: {air: DetonationEquation, ground: DetonationEquation} = {
  air: {
    RadiationRadius: (kt: number) => Math.pow( 0.802 * kt, 0.1044),
    FireballRadius: (kt: number) => Math.pow(0.0607 * kt, 0.4003),
    HeavyBlastDamage: (kt: number) => Math.pow(0.2271 * kt, 0.337),
    ModerateBlastDamage: (kt: number) => Math.pow( 0.579 * kt, 0.328),
    LightBlastDamage: (kt: number) => Math.pow(1.5691 * kt, 0.3371),
    ThirdDegreeBurns: (kt: number) => Math.pow( 0.676 * kt, 0.4088),
  },
  ground: {
    RadiationRadius: (kt: number) => Math.pow(0.8769 * kt, 0.1514),
    FireballRadius: (kt: number) => Math.pow(0.0607 * kt, 0.4003),
    HeavyBlastDamage: (kt: number) => Math.pow(0.2178 * kt, 0.3315),
    ModerateBlastDamage: (kt: number) => Math.pow(0.4744 * kt, 0.3261),
    LightBlastDamage: (kt: number) => Math.pow(1.4099 * kt, 0.3309),
    ThirdDegreeBurns: (kt: number) => Math.pow(0.676 * kt, 0.4088),
  }
}

export interface DetonationEquation {
  RadiationRadius: (kt: number) => number,
  FireballRadius: (kt: number) => number,
  HeavyBlastDamage: (kt: number) => number,
  ModerateBlastDamage: (kt: number) => number,
  LightBlastDamage: (kt: number) => number,
  ThirdDegreeBurns: (kt: number) => number,
}

// ```

// AIR BURST

// RadiationRadius = 0.802x^01044
// FireballRadius = 0.0607x^0.4003
// HeavyBlastDamage = 0.2271x^0337
// ModerateBlastDamage = 0.579x^0.328
// LightBlastDamage = 1.5691x^0.3371
// 3rdDegreeBurns = 0.676x^0.4088

// GROUND BURST
// RadiationRadius = 0.8769X^1514
// FireballRadius = 0.0607x^0.4003
// HeavyBlastDamage = 0.2178X^03315
// ModerateBlastDamage = 0.4744X^3261
// LightBlastDamage = 1.4099X^0.3309
// 3rdDegreeBurns = 0.676x^0.4088

// ```
