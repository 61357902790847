import { CSSProperties } from "react";

export interface ClockProps {
  time: number;
  realTime: number;
  status: 'new' | 'running' | 'paused' | 'stopped';

  displayGameControls: boolean;
  onPlay: () => void;
  onPause: () => void;
  onRestart: () => void;
  onStop: () => void;
}

const labelStyle: CSSProperties = {
  fontSize: 16,
  fontWeight: 'bold',
  color: 'black'
};

const timeValueStyle: CSSProperties = {
  fontSize: 16,
  color: 'blue'
};

const buttonStyle: CSSProperties = {
  outline: 'none',
  border: 'none',
  padding: 12,
  borderRadius: 4,
  minWidth: 52,
  cursor: 'pointer'
}

export function Clock(props: ClockProps) {
  // Game Time
  let seconds = props.time
  const days = Math.floor(seconds / 86400);
  seconds = seconds - (days * 86400);
  const hours = Math.floor(seconds / (3600));
  seconds = seconds - (hours * 3600);
  const minutes = Math.floor(seconds / 60);
  seconds = seconds - Math.floor((minutes * 60));
  
  // Real Time
  let realSeconds = props.realTime;
  const realDays = Math.floor(realSeconds / 86400);
  realSeconds = realSeconds - (realDays * 86400);
  const realHours = Math.floor(realSeconds / (3600));
  realSeconds = realSeconds - (realHours * 3600);
  const realMinutes = Math.floor(realSeconds / 60);
  realSeconds = realSeconds - Math.floor((realMinutes * 60));
  

  const showPlayButton = props.status === 'paused';
  const showPauseButton = props.status === 'running';
  const showStopButton = props.status === 'running' || props.status === 'paused';
  const showRestartButton = props.status === 'stopped';

  const stopTheGame = () => {
    if (!confirm('Confirm you want to stop the game?')) { return; }
    props.onStop();
  };

  return <div style={{
    backgroundColor: '#D9D9D9',
    padding: 8,
    width: 257,
    height: 150,
    gap: 2,
    justifyContent: 'space-evenly'
  }}>
    <div style={{
      flexDirection: 'row',
      justifyContent: 'space-evenly'
    }}>
      <div style={{ textAlign: 'center', gap: 8 }}>
        <span style={labelStyle}>Days</span>
        <span style={timeValueStyle}>{days}</span>
      </div>
      <div style={{ textAlign: 'center', gap: 8 }}>
        <span style={labelStyle}>Hrs</span>
        <span style={timeValueStyle}>{hours}</span>
      </div>
      <div style={{ textAlign: 'center', gap: 8 }}>
        <span style={labelStyle}>Mins</span>
        <span style={timeValueStyle}>{minutes}</span>
      </div>
      <div style={{ textAlign: 'center', gap: 8 }}>
        <span style={labelStyle}>Secs</span>
        <span style={timeValueStyle}>{Math.round(seconds)}</span>
      </div>
    </div>
    <div style={{color: '#000', textAlign: 'center'}}>
      Runtime: {realDays + ':' + realHours + ':' + realMinutes + ':' + realSeconds}
    </div>

    <div style={{
      flexDirection: 'row',
      alignItems: 'center',
      gap: 6,
      justifyContent: 'center'
    }}>
      <span style={labelStyle}>Status:</span>
      {!showPlayButton ? 
        <span style={{ color: 'green', fontSize: 16 }}>Scenario Running</span> :
        <span style={{ color: 'gray', fontSize: 16 }}>Scenario Paused</span>
      }
      
    </div>

    {props.displayGameControls ? (
      <div style={{
        flexDirection: 'row',
        justifyContent: 'space-evenly'
      }}>
        {showPlayButton ? (<button
          onClick={props.onPlay}
          style={{ ...buttonStyle, backgroundColor: '#9fff8e' }}
          title="Play scenario"
        ><img src="/icons/PlayBtn.svg"/></button>) : null}

        {showPauseButton ? (<button
          onClick={props.onPause}
          style={{ ...buttonStyle, backgroundColor: '#DBCABD' }}
          title="Pause scenario"
        ><img src="/icons/PauseBtn.svg"/></button>) : null}

        {showStopButton ? (<button
          onClick={stopTheGame}
          style={{ ...buttonStyle, backgroundColor: '#E7A0A0' }}
          title="Stop scenario"
        ><img src="/icons/StopBtn.svg"/></button>) : null}

        {showRestartButton ? (<button
          onClick={props.onRestart}
          style={{ ...buttonStyle, backgroundColor: '#8EBBFF' }}
          title="Restart"
        ><img src="/icons/RestartBtn.svg"/></button>) : null}
      </div>
    ) : null}

  </div>
}