import React, { CSSProperties } from "react";
import { ModalView } from './ModalView';
import { Location } from '@wargamer/types';
import { useModal } from '../hooks';

export function useViewLocationModal() {
  const modal = useModal();
  const open = (location: Location) => {
    modal.open(<ViewLocationModal
      location={location}
      onClose={modal.close}
    />);
  }

  return {
    open,
    close: modal.close,
    isOpen: modal.isOpen,
    root: modal.root,
  };
}

interface ViewLocationModalProps {
  location: Location;
  onClose: () => void;
}

const buttonStyle: CSSProperties = {
  fontWeight: 'bold',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  width: 117,
  height: 40,
  borderRadius: .5,
  backgroundColor: '#D9D9D9'
};

function ViewLocationModal(props: ViewLocationModalProps) {

  return <ModalView title="Location Details">
    <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 12, justifyContent: 'space-between' }}>        
        <h2 style={{maxWidth: '60%'}}>{props.location.name}<br/>
          <span style={{textTransform: 'capitalize', fontSize: 'medium'}}>
            <span style={{color: props.location.status === 'available' ? 'green' : 'gray'}}>{props.location.status}</span>
          </span>
        </h2>
        <ul>
          <li>Location Id: <b>{props.location.id}</b></li>
          <li>Region: {props.location.region}</li>
          <li>Location Type: {props.location.type}</li>
          <li>Population: {props.location.population ? props.location.population : 'Not Defined'}</li>
        </ul>
      </div>
      <div style={{border: '1px solid #ccc', padding: '10px', borderRadius: '4px'}}>
        {props.location.description ? props.location.description : 'No additional details.'}
      </div>
      <div>
        { 
          // props.location?.image_url
        }
      </div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <button style={buttonStyle} onClick={props.onClose}>Close</button>
      </div>

    </div>
  </ModalView>;
}