import { calculateBlast, createCircleFromPoint } from "@wargamer/lib/dist/utils";

export interface GetRingsOptions {
  point: [number, number];
  detonationType: 'air' | 'ground';
  kilotons: number;
  blastRadius?: number;
}

export function getRings(item: GetRingsOptions) {
  const kt = item.kilotons || 1;
  const type = item.detonationType || 'air';

  if(item.blastRadius) {

    let rings =  [
      createCircleFromPoint(item.point, item.blastRadius)
    ];

    rings[0].properties = {
      color: 'rgba(1, 253, 11, 0.3)',
      outlineColor: 'rgb(1, 253, 11)',
      type: 'ring'
    };

    return rings;
  }


  let rings =  [
    createCircleFromPoint(item.point, calculateBlast(kt, type, 'RadiationRadius')),
    createCircleFromPoint(item.point, calculateBlast(kt, type, 'FireballRadius')),
    createCircleFromPoint(item.point, calculateBlast(kt, type, 'HeavyBlastDamage')),
    createCircleFromPoint(item.point, calculateBlast(kt, type, 'ModerateBlastDamage')),
    createCircleFromPoint(item.point, calculateBlast(kt, type, 'LightBlastDamage')),
    createCircleFromPoint(item.point, calculateBlast(kt, type, 'ThirdDegreeBurns')),
  ];


  // Radiation
  rings[0].properties = {
    color: 'rgba(1, 253, 11, 0.3)',
    outlineColor: 'rgb(1, 253, 11)',
    type: 'ring'
  };

  // Fireball
  rings[1].properties = {
    color: 'rgba(253, 228, 1, 0.3)',
    outlineColor: 'rgb(253, 228, 1)',
    type: 'ring'
  };

  // HeavyBlast Damage
  rings[2].properties = {
    color: 'rgba(209, 18, 18, 0.2)',
    outlineColor: 'rgb(209, 18, 18)',
    type: 'ring'
  };

  // ModerateBlastDamage
  rings[3].properties = {
    color: 'rgba(142, 187, 255, 0.4)',
    outlineColor: 'rgb(142, 187, 255)',
    type: 'ring'
  };

  // Light Blast Damage
  rings[4].properties = {
    color: 'rgba(246, 246, 246, 0.2)',
    outlineColor: 'rgb(142, 187, 255)',
    type: 'ring'
  };

  // Thermal 3rd Degree Burns
  rings[5].properties = {
    color: 'rgba(228, 173, 69, 0.3)',
    outlineColor: 'rgb(228, 173, 69)',
    type: 'ring'
  };

  if(type === 'air') {
    rings.shift();
  }

  return rings
}