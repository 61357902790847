import { getDistance, getFlightTime, getHeading, calculateBlast } from '@wargamer/lib/dist/utils';
import { getMoveCurrent } from '../shared/move';
import { Actor, IGameState } from "@wargamer/types";
import { MissileActor } from "./types";

const errorCache = new Set();

export function getActorsInBlastZone(weapon: MissileActor, target: [number, number], state: IGameState) {
  const damageRadius = weapon.blastRadius || calculateBlast(weapon.kilotons || 1, weapon.detonationType || 'ground', 'HeavyBlastDamage');
  const actors = state.assets.getAll();
  const clock = state.clock.get();
  const destroyedActors: Actor[] = [];

  actors.forEach(l => {

    if(l.destroyable !== true || l.status === 'destroyed' || l.status === 'expended' || l.id === weapon.id) {
      return;
    }

    const targetPoint = getMoveCurrent(l, clock.time, actors)?.point;
    if(!targetPoint) {
      if(errorCache.has(l.id)) {
        return;
      }
      console.warn('Could not determine location for actor', l.id);
      errorCache.add(l.id)
      return;
    }

    const d = getDistance(targetPoint, target);

    if(d <= damageRadius) {
      destroyedActors.push(l);      
    }
  });

  return destroyedActors;
}

export function getActorsInRadius(target: [number, number], radius: number, filter: (a: Actor) => boolean, state: IGameState) {

  const actors = state.assets.getAll();
  const clock = state.clock.get();
  const destroyedActors: Actor[] = [];

  actors.forEach(l => {

    if(l.status === 'destroyed' || l.status === 'expended') {
      return;
    }

    if(filter(l) !== true) {
      return;
    }

    const targetPoint = getMoveCurrent(l, clock.time, actors)?.point;
    if(!targetPoint) {
      console.warn('Could not determine location for actor', l.id);
      return;
    }

    const d = getDistance(targetPoint, target);

    if(d <= radius) {
      destroyedActors.push(l);      
    }
  });

  return destroyedActors;
}

export function filterActors(fn: (item: {actor: Actor, pos: [number, number]}) => boolean, state: IGameState) {
  const actors = state.assets.getAll();
  const clock = state.clock.get();

  return actors.filter(l => {

    const targetPoint = getMoveCurrent(l, clock.time, actors)?.point;
    if(!targetPoint) {
      if(!errorCache.has(l.id)) {
        console.warn('Could not determine location for actor', l.id);
        errorCache.add(l.id);
      }
      return fn({actor: l, pos:null});
    }

    return fn({actor: l, pos:targetPoint});
  });
}