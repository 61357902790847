import React from "react";
import { ActorAvatar } from './ActorAvatar';

export interface GameUpdateListProps {
  updates: GameUpdate[];
  onClick: (update: GameUpdate) => void;
}

export interface GameUpdate {
  actorId?: string;
  teamId: string;
  message: string;
  type: 'sit-rep' | 'aar'
}

export function GameUpdateList(props: GameUpdateListProps) {

  const children = props.updates
  .reverse()
  .map((update, index) => {
    return <React.Fragment key={index}>
      <div style={{borderBottom: "1px solid rgba(200, 151, 60, .2)"}}></div>
      <GameUpdateItem update={update} onClick={() => props.onClick(update)}/>
    </React.Fragment>
  })

  return <div style={{maxHeight: '100%', overflowY: 'auto', gap: 12, padding: 8}}>
    {children}
  </div>
}

interface GameUpdateItemProps {
  update: GameUpdate
  onClick: () => void;
}

function GameUpdateItem(props: GameUpdateItemProps) {

  let iconColor = '#D9D9D9';
  let iconText = 'i';
  let textColor = '#D9D9D9';

  if(props.update.teamId === 'blue') {
    iconColor = '#1862D1';
    iconText = 'B';
    textColor = '#D9D9D9';
  } else if(props.update.teamId === 'red') {
    iconColor = '#D11212';
    iconText = 'R';
    textColor = '#D9D9D9';
  } else if(props.update.teamId === 'white') {
    iconColor = '#D9D9D9';
    iconText = 'W';
    textColor = 'black';
  } else if(props.update.teamId === 'info') {
    iconColor = '#D9D9D9';
    iconText = 'i';
    textColor = 'black';
  }

  return <div style={{flexDirection: 'row', gap: 8, flexShrink: 0}} onClick={props.onClick}>
    <ActorAvatar actorId={props.update.actorId}/>
    <div style={{color: '#E7E7E7'}}>
      {props.update.message}
    </div>
  </div>
}