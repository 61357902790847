import { ActorIcon } from './ActorIcon';
import { useActor, useActorRenderer, useTeam } from '../hooks';

export interface ActorAvatarProps {
  actorId: string;
  style?: 'default' | 'flat';
  size?: 'default' | 'large';
}

export function ActorAvatar(props: ActorAvatarProps) {
  const actor = useActor(props.actorId);
  const team = useTeam(actor?.teamId);
  const renderer = useActorRenderer();

  return <ActorIcon
    color={team?.color}
    size={props.size}
    style={props.style}
  >
    {renderer.renderSvgIcon(actor)}
  </ActorIcon>
}