import React from "react";


export function useModal() {
  const [root, setRoot] = React.useState<JSX.Element | null>(null);

  const open = (child: JSX.Element) => {
    setRoot(<>
      <div
        onClick={close}
        style={{
          zIndex: 1000,
          position: 'fixed',
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          backgroundColor: 'rgba(89, 89, 89, 0.8)'
      }}></div>
      <div
        style={{
          zIndex: 1001,
          position: 'fixed',
          left: '0px',
          right: 0,
          bottom: '0px',
          top: 0,
          display: 'flex',
          alignItems: "center",
          justifyContent: 'center'
      }}>
        {child}
      </div>
    </>)
  }

  const close = () => {
    setRoot(null);
  }

  return {root, open, close, isOpen: root ? true : false};
}