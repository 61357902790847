import axios from 'axios';
import { API_HOST } from '../config';
import { FeatureCollection } from 'geojson';

export async function setMapFeatures(features: FeatureCollection , gameId: string, token: string) {
  await axios.post(`${API_HOST}/game-play/${gameId}/map/features`, features, {
    headers: {
      token
    }
  });
}
