import React, { CSSProperties } from "react";
import { ModalView } from './ModalView';
import { Actor, Team } from '@wargamer/types';
import { useModal } from '../hooks';
import { ActorAvatar } from './ActorAvatar';

export interface FindActorOptions {
  title: string;
  subTitle: string;
  actionLabel: string;
  onSelection: (actor?: Actor) => void;
  actors: Actor[];
  teams: Team[];
}

export function useFindActorModal() {
  const modal = useModal();
  const open = (options: FindActorOptions) => {
    modal.open(<FindActorModal
      options={{
        ...options,
        onSelection: (actor) => {
          modal.close();
          options.onSelection(actor);
        }
      }}
      onClose={modal.close}
    />);
  }

  return {
    open,
    close: modal.close,
    isOpen: modal.isOpen,
    root: modal.root,
  };
}

interface FindActorModalProps {
  options: FindActorOptions;
  onClose: () => void;
}

const buttonStyle: CSSProperties = {
  fontWeight: 'bold',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  width: 117,
  height: 40,
  borderRadius: .5,
  backgroundColor: '#D9D9D9'
};

function FindActorModal(props: FindActorModalProps) {

  const [query, setQuery] = React.useState('');
  const [teamFilters, setTeamFilters] = React.useState(() => {
    const obj = {};
    props.options.teams.forEach(t => obj[t.id] = true);
    return obj;
  })

  const list = props.options
  .actors
  .filter(actor => {

    if(!teamFilters[actor.teamId]) {
      return false;
    }

    const q = query.toLowerCase().trim();

    if (q) {
      console.log(q, actor.name)
      if (q.includes(actor.id.toLowerCase())) {
        return true;
      } else if (actor.name.toLowerCase().includes(q)) {
        return true;
      } else if(actor?.['kilotons'] && q.includes(actor?.['kilotons'].toString())) {
        return true;
      } else if(actor.range && q.includes(actor.range.toString())) {
        return true;
      }

      return false
    } else {
      return true;
    }
  })
  .map((a) => {
    return <ActorItem
      key={a.id}
      actionLabel={props.options.actionLabel}
      actor={a}
      onClick={props.options.onSelection}
    />
  });

  const teams = props.options.teams.map((team) => {
    return <label key={team.id} style={{display: 'flex', alignItems: 'center'}}>
      <input
        type="checkbox"
        checked={teamFilters[team.id]}
        onChange={() => setTeamFilters(f => {
          const id = team.id;
          const value = f[id] ? false : true;
          return {...f, [id]: value};
        })}
      />
      {team.name}
    </label>;
  });

  return <ModalView title={props.options.title}>
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>

      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        {props.options.subTitle}
        <div style={{display: 'flex', flexDirection: 'row-reverse', flexGrow: 1}}>
          {teams}
        </div>
      </div>

      <div>
        <input value={query} onChange={e => setQuery(e.target.value)}/>
      </div>

      <div style={{flexGrow: 1, minHeight: 0, overflow: 'auto', maxHeight: '300px', gap: 8, paddingRight: 8}}>
        {list}
      </div>

      <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
        <button onClick={() => props.options.onSelection(null)}>Cancel</button>
      </div>
    </div>
  </ModalView>;
}

interface ActorItemProps {
  actor: Actor;
  actionLabel: string;
  onClick: (actor: Actor) => void;
}

function ActorItem(props: ActorItemProps) {
  return <div style={{display: 'flex', flexDirection: 'row', width: '100%', gap: 8, flexShrink: 0}}>
    <ActorAvatar actorId={props.actor.id}/>
    <div style={{display: 'flex', flexGrow: 1, flexDirection: 'column'}}>
      <h3>{props.actor.name}</h3>
      <div style={{fontSize: 'small', maxWidth: '75%'}}>{props.actor.description}</div>
    </div>
    <div style={{width: '100px'}}>
      <button onClick={() => props.onClick(props.actor)}>{props.actionLabel}</button>
    </div>
  </div>
}